.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  height: 39px;
  background-color: var(--CFD-theme-Surface-2);
  border-top: 1px solid var(--CFD-theme-System-OutlineVariant);
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: auto;
  min-width: 100%;
  padding: 12px 16px;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 16px;
}

.infoCell {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  flex-shrink: 0; 
  gap: 4px;
}

.title {
  color: var(--CFD-theme-System-Outline);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  white-space: nowrap;
}

.amount {
  color: var(--CFD-theme-System-OnSurfaceVariant);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  white-space: nowrap;
}

@media screen and (max-width: 904px) {
  .container {
    justify-content: space-between;
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    height: 54px;
  }
  .infoCell {
    flex-direction: column;
    flex-wrap: wrap;
    gap: 2px;
  }
}