.dialogHeader {
  display: flex;
  height: 70px;
  width: 100%;
  align-items: center;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
  background-color: var(--CFD-theme-State-layers-on-surface-opacity_08);
}

.dialogHeaderContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.dialogHeaderContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.dialogHeaderTitle {
  margin-left: 24px;
  color: var(--CFD-theme-System-OnSurface);
  font-family: Arial;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; 
}

.dialogHeaderBtnContainer {
  margin-right: 26px;
}

@media screen and (max-width: 904px) {
  .dialogHeader {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: var(--CFD-theme-Surface-2);
    border-bottom: 1px solid var(--CFD-theme-System-OutlineVariant);
  }
}
