.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.btnContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.tableHeadersTitle {
  margin-bottom: 0;
  color: var(--CFD-theme-System-OnSurfaceVariant);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
}

.cellTextBtnIcon {
  outline: none;
  border: none;
  background-color: transparent;
  text-align: center;
  margin-top: 5px;
  padding: 0;
}