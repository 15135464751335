.inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.supportingText {
    margin-top: 2px;
    margin-bottom: 0;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-left: 16px;
    text-align: start;
    color: var(--CFD-theme-System-OnSurfaceVariant);
  }
  
  .supportingTextError {
    margin-top: 2px;
    margin-bottom: 0;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-left: 16px;
    text-align: start;
    color: var(--CFD-theme-System-Error);
  }

  .inputTitle {
    margin-top: 2px;
    margin-bottom: 2px;
    margin-left: 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.4px;
    text-align: start;
    color: var(--CFD-theme-System-OnSurfaceVariant);
  }

  .disabled {
    opacity: 0.3;
  }