.headerWrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--CFD-theme-Surface-2);
  border-bottom: 1px solid var(--CFD-theme-System-OutlineVariant);
  max-height: 130px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.subHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 24px 8px 24px;
}

.subHeaderTitle {
  color: var(--CFD-theme-System-Outline);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
}

.mainContentWrapper {
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

}

.titleWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  color: var(--CFD-theme-System-OnSurfaceVariant);
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; 
}

.subTitle {
  color: var(--CFD-theme-System-OnSurfaceVariant);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.1px;
}


.searchWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 378px;
  width: 100%;
}

@media screen and (max-width: 1239px) {
  .searchWrapper {
    max-width: 300px;
  }
}

@media screen and (max-width: 800px) {
  .searchWrapper {
    max-width: 230px;
  }
}

@media screen and (max-width: 700px) {

  .titleWrapper {
    display: none;
  }
  .searchWrapper {
    max-width: unset;
  }
}


