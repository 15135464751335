.mainWrapper {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  padding: 18px 8px 18px 16px;
  gap: 4px;
  background-color: var(--CFD-theme-Surface-1);
  justify-content: space-between;
  align-items: center;
}

.headerTitle {
  color: var(--CFD-theme-System-OnSurface);
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.headerBtnContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.btn { 
  height: 32px;
  width: 32px;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  border-radius: 100px;
  cursor: pointer;
}

.btn:hover {
  background-color: var(--CFD-theme-State-layers-on-surface-opacity_16);
}

.btnContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px
}

.btnTitle {
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.svg {
  width: 48px;
  height: 50px;
}

.bodyWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
}

.footer {
  height: 129px;
  background-color: var(--CFD-theme-Surface-1);
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.agreeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 8px;
  gap: 12px;
}

.agreeTitle {
  color: var(--CFD-theme-System-OnSurface);
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.disabledAgreeTitle {
  color: var(--CFD-theme-System-OnSurface);
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
  opacity: 0.5
}

.footerBtnContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 8px 16px 24px 16px;
}

.loadingContainer {
  height: 32px;
  width: 32px;
  display: flex;
}

@media screen and (max-width: 600px) {
  .headerTitle {
    font-size: 18px;
  }
}
