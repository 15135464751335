.settingWrapper {
  display: flex;
  flex-direction: column;
  background: var(--CFD-theme-System-Surface);
  color: var(--CFD-theme-System-OnSurface);
  border-radius: 4px;
  width: 100%;
  overflow-y: scroll;
  scrollbar-color: var(--CFD-theme-System-OutlineVariant) transparent;
  scrollbar-width: thin;
  min-height: 336px;
}

.svg {
  width: 24px;
  height: 24px;
}

.settingHeader {
  height: 44px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 24px 16px;
}

.settingHeaderTitle {
  font-family: Arial;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; 
}

.settingsFirstSection {
  display: flex;
  align-items: center;
}

.settingsFirstSectionTitle {
  margin-left: 8px;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: var(--CFD-theme-System-OnSurface);
}

.settingsSecondSection {
  display: flex;
  align-items: center;
}

.settingsSecondSectionTitle {
  margin-left: 8px;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: var(--CFD-theme-System-OnSurface)
}

@media screen and (max-width: 1024px) {
  .settingWrapper {
    width: 100%;
  }
}
