.header {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 8px;
  background-color: var(--CFD-theme-Surface-1);
  border-bottom: 1px solid var(--CFD-theme-System-OutlineVariant);
}

.contentWrapper {
  display: flex;
  height: 48px;
  width: 100%;
  gap: 16px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.contentTitle {
  font-family: Arial;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: var(--CFD-theme-System-OnSurface);
}

.btn { 
  height: 32px;
  width: 32px;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  border-radius: 100px;
  cursor: pointer;
}

.btn:hover {
  background-color: var(--CFD-theme-State-layers-on-surface-opacity_16);
}

@media screen and (max-width: 904px) {
  .header {
    padding: 8px 16px 8px 16px;
  }
}

@media screen and (max-width: 600px) {
  .contentTitle {
    font-size: 22px;
    line-height: 28px;
  }
}