.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  width: 100vw;
  height: 100vh;
}

.navRailWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  flex-shrink: 0;
  width: 80px;
  padding-top: 12px;
  background: var(--CFD-theme-Surface-2);
  position: fixed;
  z-index: 3;
}

.logoBlock {
  height: 54px;
  width: 54px;
  border: 1px solid var(--CFD-theme-System-OnSurfaceVariant);
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.logoBtn {
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  gap: 18px;
}

.menuItem {
  display: flex;
  font-size: 12px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}
.selectedMenuItem {
  font-size: 13px;
  color: var(--CFD-theme-System-OnSurface);
  /* font-weight: bold; */
  text-wrap: nowrap;
}

.menuItemImageWrapper {
  border-radius: 25px;
  cursor: pointer;
  height: 24px;
  width: 24px;
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
}
.selectedMenuImageItem {
  background: var(--CFD-theme-System-SecondaryContainer);
}
.menuItemImageWrapper:hover {
  background: var(--CFD-theme-State-layers-on-surface-opacity_08);
}

.menuLink {
  cursor: pointer;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}

.children {
  overflow: auto;
  width: 100vw;
  height: 100vh;
}

@media screen and (max-width: 599px) {
  .wrapper {
    display: none;
  }
  .navRailWrapper {
    display: none;
  }
}