.footerComponentWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 4px 12px 4px 8px;
  gap: 12px;
  width: 100%;
  height: 32px;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.30);
  background-color: var(--CFD-theme-Surface-4);
}

.footerComponentIconWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footerComponentIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 32px;
}

.footerIconBadge {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--CFD-theme-System-Error);
  color: var(--CFD-theme-System-OnError);
  border-radius: 50%;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
  padding: 4px 0;
  width: 17px; 
  height: 8px; 
  left: 18px;
  top: 0;
}

.footerComponentText {
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  white-space: nowrap;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}

.svgSize {
  width: 24px;
  height: 24px;
}

.footerComponentWrapper:hover {
  background-color: var(--CFD-theme-State-layers-on-surface-variant-opacity_08);
}

.footerComponentWrapperSelect {
  background-color: var(--CFD-theme-State-layers-on-surface-variant-opacity_16);
}