.wrapper {
  display: flex;
  flex-direction: column;
  background: var(--CFD-theme-System-Surface);
  border: 1px solid var(--CFD-theme-System-OutlineVariant);
  color: var(--CFD-theme-System-OnSurface);
  border-radius: 6px;
  height: 240px;
  width: 100%;
}

.title {
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  padding: 16px;
}

.balance {
  text-align: left;
  padding: 15px 0px 15px 19px;
  font-size: 16px;
}

.content {
  display: flex;
  padding: 5px 15px;
}
