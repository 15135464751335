.pageContainer {
  position: relative; 
  background-color: var(--CFD-theme-System-Surface);
}

.contentContainer {
  height: 100%;
  overflow-y: scroll;
  position: relative;
  scrollbar-color: var(--CFD-theme-System-OutlineVariant) transparent;
  scrollbar-width: thin;
}

.appContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--CFD-theme-System-Surface);;
  width: 100%;
  /* -webkit-rtl-ordering: visual; */
}

.app {
  transition: background-color 200ms linear;
}

.arabicFont {
  font-family: 'Cairo-Regular, sans-serif !important';
}

.defaultFont {
  font-family: 'Arial Regular', Arial, sans-serif;
}
