.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 16px;
  overflow: scroll;
}

.cardWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 16px;
  padding-bottom: 16px;
  width: 100%;
  gap: 16px;
}

.cardWrapperInput {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
}

.cardOptions {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.addCardBtnContainer {
  width: 120px;
}

.cardWrapperText {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-OnSurface);
}

.label {
  margin-left: 16px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.4px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
}

.savedCardsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
}

.infoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  background-color: var(--CFD-theme-System-Inversesurface);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  padding: 16px;
}

.infoText {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.25px;
  color: var(--CFD-theme-System-Inverseonsurface);
}

.checkboxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.infoBtn {
  width: 180px;
}

.savedCardsText {
  margin-top: 0;
  color: var(--CFD-theme-System-Outline);
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: 0.5px;
}

.footer {
  position: fixed;
  bottom: 0;
  box-sizing: border-box;
  width: 530px;
  display: flex;
  padding: 24px;
  background-color: var(--CFD-theme-Surface-1);
  border-top: 1px solid var(--CFD-theme-System-OutlineVariant);
}

.btnContainer {
  width: 100%;
}

.btnText {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  text-align: center;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.1px;
}

.checkboxTitle {
  color: var(--CFD-theme-System-Outline);
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}

@media screen and (max-width: 904px) {
  .wrapper {
    padding-bottom: 46px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .footer {
    padding: 24px 16px 24px 16px;
  }
}

@media screen and (max-width: 599px) {
  .wrapper {
    padding-bottom: 80px;
  }
  .footer {
    width: 100%;
  }
}
