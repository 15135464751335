.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
  gap: 14px;
  overflow: scroll;
}

.successSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  gap: 16px;
}

.footer {
  position: fixed;
  bottom: 0;
  box-sizing: border-box;
  width: 530px;
  display: flex;
  gap: 24px;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: var(--CFD-theme-Surface-2);
  border-top: 1px solid var(--CFD-theme-System-OutlineVariant);
}

.btnContainer {
  width: 100%;
}

.btnText {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  text-align: center;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.1px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--CFD-theme-System-OutlineVariant);
  gap: 8px;
  padding-top: 24px;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.contentDate {
  margin-top: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.contentTitle {
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}

.contentText {
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-OnSurface);
}

@media screen and (max-width: 1239px) {
  .footer {
    width: 100%;
  }
}