.footer {
  padding-top: 0;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
}

.btnContainer {
  width: 70px;
}