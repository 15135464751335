.accardionCellSummary {
  height: 72px;
  width: 100%;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.accardionCellWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 8px;
}

.accardionDropDownWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--CFD-theme-Surface-3);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
}

.accardionCellText {
  color: var(--CFD-theme-System-OnSurface);
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
  margin-bottom: 0;
}


.accardionDropDownInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.accardionInfoWrapper {
  display: flex;
  flex-direction: row;
  padding-right: 24px;
  gap: 8px;
}

.accardionCellStatusWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 8px;
}

.accardionInfoWrapperBorder {
  padding-right: 24px;
  border-right: 1px solid var(--CFD-theme-System-OutlineVariant);
}

.paddingLeft {
  padding-left: 24px;
}

.statusUnlocked {
  margin-left: 5%;
  border-radius: 8px;
  width: 77px;
  height: 24px;
  background-color: color-mix(in srgb, var(--CFD-theme-Custom-Green), transparent 88%);
  color: var(--CFD-theme-Custom-Green);
}

.statusLocked {
  margin-left: 5%;
  border-radius: 8px;
  width: 77px;
  height: 24px;
  background-color: color-mix(in srgb, var(--CFD-theme-Custom-Red), transparent 88%);
  color: var(--CFD-theme-Custom-Red);
}

.statusActive {
  border-radius: 8px;
  width: 77px;
  height: 24px;
  background-color: color-mix(in srgb, var(--CFD-theme-Custom-Purple), transparent 88%);
  color: var(--CFD-theme-Custom-Purple);
}

.accardionStatusCellText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: 0.4px;
}
.paddingCummalitivTotal {
  padding-left: 10%;
}

.paddingCummalitivTotalLocked {
  padding-left: 20%;
}

.paddingDays {
  padding-left: 8px;
}

.accardionCellTimeLeftHoursWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

.accardionCellTimeLeftHoursCell {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}

.accardionCellTimeLeftWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

.accardionCellTimeLeftTitle {
  color: var(--CFD-theme-System-Outline);
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.5px;
}


.accardionCellTimeLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 2px;
  border: 1px solid var(--CFD-theme-System-Outline);
  width: 36px;
  height: 28px;
}

.accardionCellTimeLeftText {
  color: var(--CFD-theme-System-OnSurface);
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.1px;
}

.accardionCellTimeLeftDots {
  color: var(--CFD-theme-System-OnSurface);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.1px;
}

.accardionInfoTitle {
  color: var(--CFD-theme-System-Outline);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.accardionInfoText {
  color: var(--CFD-theme-System-OnSurface);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
}

@media screen and (max-width: 1240px) {
  .accardionCellStatusWrapper {
    padding-left: 12px;
  }
}