.wrapper {
  background-color: var(--CFD-theme-System-Surface);
  width: 100%;
  height: 100%;
  padding-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 32px;
  overflow: auto;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.headerSegmentBtnTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
  max-width: 180px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}

.pageSection {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border: 1px solid var(--CFD-theme-System-OutlineVariant);
    background-color: var(--CFD-theme-Surface-1);
    border-radius: 6px;
    width: 100%;
    height: 100%;
}

.headerSegmentBtnIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media screen and (max-width: 650px) {
  .pageSection {
    height: unset;
  }
}

@media screen and (max-width: 599px) {
  .wrapper {
    padding: 16px;
  }

  .headerSegmentBtnIcon {
    display: none;
  }
}

