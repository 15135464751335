.identityVerificationContentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 16px;
}

.identityVerificationProgreesContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.identityVerificationProgreesContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.identityVerificationProgreesText {
  color: var(--CFD-theme-System-OnSurfaceVariant);
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.25px;
  white-space: nowrap;
}

.identityVerificationBtnContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.identityVerificationCompleteLabel {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--CFD-theme-System-Outline);
  text-align: center;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
  padding: 10px 12px;
}

.docsProgreesListContainer {
  display: flex;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
}

@media screen and (max-width: 410px) {
  .identityVerificationProgreesText {
    letter-spacing: 0.1px;
    font-size: 12px;
  }
}

.settingsFirstSectionContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;

}

.settingsFirstSectionContentTitle {
  color: var(--CFD-theme-System-OnSurface);
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.settingsFirstSectionContentText {
  margin-top: 20px;
  text-align: start;
  color: var(--CFD-theme-System-OnSurface);
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.settingsStatusContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.successDocs {
  color: #116A2C;
}

.unSuccessDocs {
  color: #DB2A3F;
}

.statusText {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

}


.settingsFirstSectionContentBtnContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 16px 0px;
  box-sizing: border-box;
}

.btnContainer {
  width: 100px;
}

.settingsSecondSectionContentContainer {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.settingsSecondSectionContentText {
  margin-top: 20px;
  margin-right: 30px;
  text-align: start;
  color: var(--CFD-theme-System-OnSurface);
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.settingsSecondSectionContentBtnContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  box-sizing: border-box;
  padding: 16px 0px;
}

.btnSecondContainer {
  width: 120px;
}

.settings2FABtn {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.settings2FABtnParagraph {
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
  margin-left: 16px;
}

.settings2FABtnSecond {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

}

.settingsVerificationParagraph {
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}