.radioBtnContainer {
  display: flex;
  flex-direction: row;
}

.assetsCheckContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
} 

.assetsCheckContainerTitle {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}

.assetsCheckContainerAmount {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-OnSurface);
}
