.accardionCellTimeLeftHoursWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

.accardionCellTimeLeftHoursCell {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}

.accardionCellTimeLeftDots {
  color: var(--CFD-theme-System-OnSurface);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.1px;
}

.accardionCellTimeLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 2px;
  border: 1px solid var(--CFD-theme-System-Outline);
  width: 36px;
  height: 28px;
}

.accardionCellTimeLeftText {
  color: var(--CFD-theme-System-OnSurface);
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.1px;
}

.accardionCellTimeLeftDots {
  color: var(--CFD-theme-System-OnSurface);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.1px;
}

.accardionCellTimeLeftTitle {
  color: var(--CFD-theme-System-Outline);
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.5px;
}

.accardionCellTimeLeftWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

@media screen and (max-width: 1240px) {
  .accardionCellStatusWrapper {
    padding-left: 12px;
  }
  .accardionCellTimeLeftHoursCell {
    gap: 4px;
  }
}

@media  screen and (max-width: 400px ) {
  .accardionCellTimeLeft {
    width: 28px;
  }
  .accardionCellReleseTimer {
    gap: 2px;
  }
  .accardionCellTimeLeftHoursCell {
    gap: 2px;
  }
}