.currencyContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.optionsWrapper {
  display: flex;
  flex-direction: column;
}

.currencyTitle {
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.25px;
  margin-bottom: 0;
  color: var(--CFD-theme-System-OnSurface);
}

.balanceTitle {
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  margin-bottom: 0;
  color: var(--CFD-theme-System-OnSurfaceVariant);

}

.currencyIcon {
  width: 20px;
  height: 20px; 
}