.headerWrapper {
  box-sizing: border-box;
  padding-left: 24px;
  padding-top: 16px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 64px;
  background-color: var(--CFD-theme-Surface-2);
  color: var(--CFD-theme-System-OnSurface);
  border-bottom: 1px solid var(--CFD-theme-System-OutlineVariant);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.header {
  display: flex;
  flex-direction: row;
  background-color: inherit;
  overflow-y: scroll;
}

.headerAccountsWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: inherit;
  outline: none;
  border-top: none;
  border-left: none;
  border-right: none;
  margin-right: 20px;
  border-bottom: 2px solid transparent;
  transition: border-bottom 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.active {
  border-bottom: 2px solid var(--CFD-theme-System-Tertiary);
}

.headerAccountsParagraph {
  text-align: start;
  text-wrap: nowrap;
  margin-left: 8px;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;;
  color: var(--CFD-theme-System-OnSurface);
}

.headerBalanceWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  background-color: var(--CFD-theme-Surface-2);
}

.totalBalanceWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  justify-content: space-between;
}

.totalBalance {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  color: var(--CFD-theme-System-Outline);
}

.totalBalanceTitle {
  color: var(--CFD-theme-System-Outline);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.totalBalanceAmountWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  min-height: 28px;
}

.totalBalanceAmount {
  color: var(--CFD-theme-System-OnSurface);
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.totalBalanceCurrency {
  color: var(--CFD-theme-System-Outline);
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  letter-spacing: 0.5px;
}

.totalBalanceInfoWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}

.totalBalanceTotalAmount {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.totalBalanceTotalAmountTitle {
  color: var(--CFD-theme-System-Outline);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.totalBalanceTotalAmountCurrency {
  color: var(--CFD-theme-System-OnSurface);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.25px;
}

.borderYVector {
  border-left: 1px solid var(--CFD-theme-System-OutlineVariant);
  border-right: 1px solid var(--CFD-theme-System-OutlineVariant);
  padding: 0 24px;
}

.optionsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  background-color: var(--CFD-theme-Surface-2);
}

.optionsSegmentBtnWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 44px;
  max-width: 500px;
}

.optionsFilterWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 530px;
  gap: 12px;
}

.datePickerWrapper {
  display: flex;
  flex-direction: row;
}

.selectStatusWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.selectLockedPeriodWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header::-webkit-scrollbar {
  display: none;
}

.header {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.optionsFilters {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 12px;
}

@media screen and (max-width: 1240px) {
  .headerAccountsWrapper {
    min-width: 150px;
  }

  .headerBalanceWrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .totalBalanceWrapper {
    width: 100%;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--CFD-theme-System-OutlineVariant);
  }

  .selectLockedPeriodWrapper {
    display: none;
  }

  .totalBalanceInfoWrapper {
    width: 100%;
    padding-top: 16px;
  }

  .optionsWrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 18px;
    padding-top: 0;
  }

  .optionsFilterWrapper {
    max-width: unset;
    width: 100%;
  }
}

@media screen and (max-width: 905px) {
  .totalBalanceInfoWrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  .borderYVector {
    padding: 0;
    border: none;
  }
}

@media screen and (max-width: 750px) {
  .optionsFilterWrapper {
    justify-content: space-between ;
  }
}


@media screen and (max-width: 600px) {
  .selectStatusWrapper {
    display: none;
  }

  .totalBalanceCurrency {
    font-size: 14px;
  }
}
