.headerAccountsWrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: inherit;
  outline: none;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 14px 24px;
  border-bottom: 2px solid var(--CFD-theme-System-Surface);
  transition: border-bottom 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.headerAccountsWrapper:hover {
  background-color: var(--CFD-theme-State-layers-on-surface-variant-opacity_08);
}

.active {
  border-bottom: 2px solid var(--CFD-theme-System-Tertiary);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.svg {
  height: 16px;
  width: 16px;
  margin-left: 6px;
}

.img {
  height: 20px;
  width: 20px;
}

.headerAccountsParagraph {
  margin-left: 8px;
  margin-top: 0;
  margin-bottom: 0;
  white-space: nowrap;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: var(--CFD-theme-System-OnSurface);
}