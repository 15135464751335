.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.dialogWrapper {
  width: 530px;
  margin-right: inherit;
  height: 100%;
  background-color: var(--CFD-theme-System-Surface);
  padding: 0;
  border: none;
  z-index: 1001;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.30), 0px 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px 0px rgba(0, 0, 0, 0.30), 0px 6px 10px 4px rgba(0, 0, 0, 0.15);
}

.arabicFont {
  font-family: 'Cairo-Regular, sans-serif !important';
}

.defaultFont {
  font-family: 'Arial Regular', Arial, sans-serif;
}

@media screen and (max-width: 599px) {
  .dialogWrapper {
    margin: 0;
    width: 100%;
    min-height: 100%;
    height: 100%;
  }
}