.dialogFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    margin-bottom: 32px;
    padding: 0 24px;
    box-sizing: border-box;
}

.dialogFooterButton {
    max-width: 470px;
    width: 100%;
}
