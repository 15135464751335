.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.container {
  position: relative;
  width: 380px;
  height: calc(100% - 116px);
  min-height: 624px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--CFD-theme-Surface-5);
  border-radius: 14px;
  background-color: var(--CFD-theme-Surface-1);
  box-shadow: 0px 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px 0px rgba(0, 0, 0, 0.30);
  position: fixed;
  max-width: 380px;
  width: 100%;
  height: auto;
  max-height: 624px;
  z-index: 10;
}

.mobileWrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--CFD-theme-Surface-5);
  border-radius: 14px;
  background-color: var(--CFD-theme-Surface-1);
  box-shadow: 0px 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px 0px rgba(0, 0, 0, 0.30);
  position: absolute;
  max-width: 380px;
  width: 100%;
  height: auto;
  max-height: 624px;
  z-index: 10;
  top: 0;
}

.titleWrapper {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 8px 16px 16px;
}

.title {
  color: var(--CFD-theme-System-OnSurface);
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.footer {
  display: none;
}

.btnFooterContainer {
  width: 100%;
}

.crossContainer {
  display: none;
}

@media screen and (max-width: 600px) {
  .wrapper {
    position: fixed;
    width: 100%;
    max-width: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    min-height: 100dvh;
    border-radius: 0;
    border: none;
  }

  .mobileWrapper {
    position: fixed;
    width: 100%;
    max-width: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    min-height: 100dvh;
    border-radius: 0;
    border: none;
  }

  .titleWrapper {
    padding: 16px;
  }

  .crossContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0;
  }

  .footer {
    display: flex;
    width: 100%;
    padding: 24px 16px;
    box-sizing: border-box;
  }

}

