.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    gap: 24px;
    overflow: auto;
  }
  
  .mainTitle {
    width: 100%;
    text-align: start;
    font-family: Arial;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; 
    margin-bottom: 16px;
    color: var(--CFD-theme-System-OnSurfaceVariant);
  }
  
  .label {
    margin-left: 16px;
    color: var(--CFD-theme-System-OnSurfaceVariant);
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; 
    letter-spacing: 0.4px;
  }
  
  .inputContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }
  
  .formContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    align-items: flex-start;
    justify-content: center;
  }
  
  .footer {
    position: fixed;
    bottom: 0;
    box-sizing: border-box;
    width: 530px;
    display: flex;
    padding: 24px;
    background-color: var(--CFD-theme-Surface-1);
    border-top: 1px solid var(--CFD-theme-System-OutlineVariant);
  }
  
  .btnContainer {
    width: 100%;
  }
  
  .btnText {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    text-align: center;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    letter-spacing: 0.1px;
  }

  @media screen and (max-width: 599px) {
    .footer {
      width: 100%;
    }
  }