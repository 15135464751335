.labelContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.labelText {
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-Outline);
}