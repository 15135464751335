.status {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.statusText {
  color: var(--CFD-theme-System-OnSurface);
  text-align: center;
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 6px;
  background: var(--CFD-theme-State-layers-on-surface-opacity_08);
  padding: 4px 12px;
}
