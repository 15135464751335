.selectContainer {
  position: relative;
  width: 100%;
  max-width: 154px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
  background-color: var(--CFD-theme-Surface-2);
}

.select {
  display: flex;
  align-items: center;
  padding: 6px 8px 6px 16px;
  border: 1px solid var(--CFD-theme-System-Outline);
  border-radius: 8px;
  cursor: pointer;
  gap: 8px;
  background-color: var(--CFD-theme-Surface-2);
}

.dropdown {
  position: absolute;
  margin-top: 4px;
  z-index: 3;
  border-radius: 16px;
  background-color: var(--CFD-theme-Surface-4);
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
}

.text {
  color: var(--CFD-theme-System-OnSurfaceVariant);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
  margin-bottom: 0;
}

.buttonContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  background-color: var(--CFD-theme-Surface-5);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.cancelButtonWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 12px;
}

.submitButtonWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 16px;
  padding-bottom: 12px;
}