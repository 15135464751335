.platformSettingsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.languageContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  align-items: center;
  width: 100%;
  padding: 20px 16px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--CFD-theme-System-OutlineVariant);
}

.selectContainer {
  display: flex;
  width: 320px;
  min-width: 320px;
}

.platformSettingsTitle {
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}

.currencyContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  width: 100%;
  padding: 20px 16px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--CFD-theme-System-OutlineVariant);
}

.appearanceContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  width: 100%;
  padding: 20px 16px;
  box-sizing: border-box;
}

@media screen and (max-width: 500px) {
  .languageContainer {
    flex-direction: column;
    gap: 8px;
  }

  .currencyContainer {
    flex-direction: column;
    gap: 8px;
  }

  .appearanceContainer {
    flex-direction: column;
    gap: 8px;
  }

  .platformSettingsTitle {
    width: 320px;
    min-width: 270px;
    text-indent: 12px;
  }
}

@media screen and (max-width: 400px) {
  .selectContainer {
    width: 100%;
    min-width: 0;
  }

  .platformSettingsTitle {
    width: 100%;
    min-width: 0;
  }
}
