.footerWrapper {
  padding-bottom: 24px;
  padding-top: 16px;
  padding-left: 24px;
  padding-right: 24px;
}

.confirmContainer {
  width: 100%;
}

.btnsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.btnContainer {
  width: 100%;
}