.transferWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 24px;
  gap: 16px;
  max-height: calc(100dvh - 178px);
  overflow: scroll;
}

.transferForm {
  width: 100%;
}

.label {
  color: var(--CFD-theme-System-OnSurfaceVariant);
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  letter-spacing: 0.15px;
  margin-bottom: 10px;
}

.changeBtnSection {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.line {
  width: 100%;
  border: 0;
  height: 2px;
  background-color: var(--CFD-theme-System-OutlineVariant);
}

.changeBtn {
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.footerWrapper {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 16px;
  gap: 16px;
  width: 530px;
  background-color: var(--CFD-theme-Surface-1);
  border-top: 1px solid var(--CFD-theme-System-OutlineVariant);
}

.btnConfirmContainer {
  width: 100%;
}

.rateContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
}

.rate {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.rateFrom {
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}

.rateTo {
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  letter-spacing: 0.15px;
  color: var(--CFD-theme-System-Primary);
}

.btnConfirmContainer {
  width: 100%;
}

@media screen and (max-width: 599px) {
  .footerWrapper {
    width: 100%;
  }
}

