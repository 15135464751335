.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
  font-size: 18px;
}

.chartWrapper {
  margin-top: 20px;
  width: 100%;
  min-height: 400px;
  overflow-y: hidden;
  overflow-x: auto;
  position: relative;
}

.noDataWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 180px;
  width: 100%;
  font-size: 18px;
  margin-top: 100px;
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
  font-size: 18px;
}

.emptyContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: '16px';
}

.emptyContainer span {
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.emptyContainer span:first-of-type {
  color: var(--CFD-theme-System-OnSurface);
  margin-top: 16px;
  margin-bottom: 4px;
}

.emptyContainer span:last-of-type {
  color: var(--CFD-theme-System-OnSurfaceVariant);
}

.headerWrapper {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 48px;
  background-color: var(--CFD-theme-System-Surface);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: var(--CFD-theme-System-OnSurface);
}

.header {
  display: flex;
  flex-direction: row;
  background-color: inherit;
  overflow-y: auto;
  scrollbar-width: none; 
}