.dialogFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 32px;
    box-sizing: border-box;
    width: 100%;
    background-color: var(--CFD-theme-Surface-3);
}
