.filePickerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px dashed var(--CFD-theme-System-Outline);
    padding: 16px;
    margin-bottom: 29px;
}
.filePickerTitle {
    color: var(--CFD-theme-System-OnSurface);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
}

.hidenDocsPicker {
    opacity: 0;
    height: 0;
    width: 0;
    line-height: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
}

.addFileBtn {
    width: 71px;
    height: 20px;
    margin-top: 18px;
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
    gap: 2px;
    background-color: inherit;
    outline: none;
    border: none;
    color: var(--CFD-theme-System-Tertiary);
    cursor: pointer;
    align-items: center;
}