.settings2FAVariantWrapper {
    display: flex;
}

.settings2FAVariantContainer {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}

.settings2FATitleContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.settings2FABtnTitle {
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: var(--CFD-theme-System-OnSurface);
}

.settings2FAIconContainer {
    display: flex;
    flex-direction: row;
    width: 34px;
    height: 34px;
    background-color: transparent;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
}

.settings2FAIconContainer:hover {
    background-color: var(--CFD-theme-State-layers-on-surface-variant-opacity_08);
}

.settings2FABtn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
}

.settings2FABtnParagraph {
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: var(--CFD-theme-System-OnSurfaceVariant);
    margin-left: 16px;
}

.errorBackground {
    background-color: rgba(219, 42, 63, 0.25);
}

.succesBackground {
    background-color: rgba(17, 106, 44, 0.25);
}

.successDocs {
    color: #116A2C;
}

.unSuccessDocs {
    color: #DB2A3F;
}

.switchContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}