.contentWrapper {
    padding: 32px 32px 0 32px;
    height: 100%;
    justify-content: center;
    display: flex;
    gap: 24px;
    box-sizing: border-box;
    width: 100%;
}

.marketBoard {
    max-width: 330px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-width: 330px;
}

.tradingBoard {
    width: 100%;
    min-width: 780px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            min-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            max-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.tradingBoard.tradingBoardFullWidth {
    width: 100%;
    max-width: 100%;
}

.trading {
    display: flex;
    height: 100%;
    min-height: 250px;
    display: flex;
    gap: 24px;
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            min-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            max-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

@media screen and (max-width: 1650px) {
    .contentWrapper {
        gap: 16px;
        padding-left: 16px;
        padding-right: 16px;
    }
    .marketBoard {
        gap: 16px;
    }
    .trading {
        gap: 16px;
    }
}

@media screen and (min-width: 1990px) {
    .marketBoard {
        width: 25%;
    }
    .tradingBoard {
        max-width: none;
    }
}
