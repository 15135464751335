.numberInputContainer {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid var(--CFD-theme-System-Outline);
    background-color: transparent;
    padding: 8px 16px;
    border-radius: 4px;
}

.numberInputContainer.focused {
    border: 3px solid var(--CFD-theme-System-Primary);
}

.numberInputContainer .numberInputContainer__btnRow {
    position: absolute;
    right: 0;
    height: 40px;
    top: 0;
    display: flex;
    align-items: center;
}

.numberInputContainer .numberInputContainer__btnRow button {
    outline: unset;
    border: unset;
    background-color: unset;
    cursor: pointer;
}

.numberInputContainer input {
    border: none;
    background-color: transparent;
    color: var(--CFD-theme-System-OnSurfaceVariant);
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    width: calc(100% - 75px);
    outline: unset;
}

.numberInputContainer input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px inherit inset;
    -webkit-text-fill-color: var(--CFD-theme-System-OnSurfaceVariant) !important;
    -webkit-background-clip: text;
}

.numberInputContainerLabel {
    color: var(--CFD-theme-System-OnSurfaceVariant);
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.4px;
    margin-left: 15px;
    margin-bottom: 5px;
    display: inline-block;
}

.numberInputContainer input:focus {
    outline: none;
}
