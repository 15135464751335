.dialogHeader {
    padding: 24px;
    color: var(--CFD-theme-System-OnSurface);
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
}

.dialogContent {
    background-color: var(--CFD-theme-State-layers-surface-variant-opacity_16);
    list-style: unset;
    width: 312px
}

.dialogContent > li {
    padding: 8px 24px;
    border-bottom: 1px solid var(--CFD-theme-State-layers-outline-opacity_16);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dialogContent li:last-of-type {
    border: unset;
}

.dialogContent .label {
    color: var(--CFD-theme-System-OnSurfaceVariant);
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
}

.dialogContent .value {
    color: var(--CFD-theme-System-OnSurface);
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
}

.dialogFooter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 24px;
    gap: 8px
}
