.marketContainer {
    border-radius: 6px;
    border: 1px solid var(--CFD-theme-State-layers-outline-opacity_16);
    background-color: var(--CFD-theme-Surface-1);
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
              min-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
              max-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    overflow: hidden;
}

.marketContainerHeader {
    padding: 16px;
    font-family: Arial;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    color: var(--CFD-theme-System-OnSurface);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.closeButton {
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.searchContainer {
    padding: 16px 12px 0 12px;
}

.chipsContainer {
    padding: 24px 16px;
}

.chipsContainer > div {
    margin-right: 8px;
    margin-bottom: 8px;
}

.marketTable {
    display: flex;
    flex-direction: column;
}

.marketTableHeader {
    padding: 0 16px 0 16px;
    display: flex;
    justify-content: space-between;
    flex-shrink: 0; 
}

.marketTableHeader > span {
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: var(--CFD-theme-System-Outline);
    cursor: pointer;
    display: flex;
    align-items: center;
}

.marketTableList {
    background-color: var(--CFD-theme-Surface-3);
    overflow-y: auto;
    flex-grow: 1;
}

.marketTableItem {
    display: flex;
    align-items: center;
    height: 65px;
    padding-left: 4px;
    padding-right: 24px;
    border-bottom: 1px solid var(--CFD-theme-System-OutlineVariant);
    justify-content: space-between;
}

.btnAsset {
    outline: none;
    border: none;
    background-color: transparent;
    width: 100%;
    display: flex;
    align-items: center;
    height: 65px;
    padding-left: 4px;
    padding-right: 24px;
    border-bottom: 1px solid var(--CFD-theme-System-OutlineVariant);
    justify-content: space-between;
    cursor: pointer;
}

.addToWatchList {
    cursor: pointer;
    height: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 4px;
}

.marketTableItemAsset {
    display: flex;
    align-items: center;
}

.marketTableItemAsset span {
    margin-left: 12px;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: var(--CFD-theme-System-OnSurface)
}

.marketTableItemPrices {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.marketTableItemPrices span:first-of-type {
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: var(--CFD-theme-System-OnSurfaceVariant)
}

.marketTableItemPrices span:last-of-type {
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
}

.imgSize {
    width: 24px;
    height: 24px;
}

@media screen and (max-width: 600px) {
    .marketTableHeader {
        margin-bottom: 0;
        padding: 0;
    }
}

@media screen and (max-width: 425px) {
    .marketTableItemAsset span {
        font-size: 12px;
        line-height: 16px;
    }
}

