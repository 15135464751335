.inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  position: relative;
}

.supportingTextError {
  margin-top: 2px;
  margin-bottom: 0;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: start;
  color: var(--CFD-theme-System-Error);
  position: absolute;
  top: 42px;
  left: 16px;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.cardIconWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  border-left: 1px solid var(--CFD-theme-System-Outline);
}