.headerWrapper {
  box-sizing: border-box;
  padding-top: 8px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 48px;
  background-color: var(--CFD-theme-System-Surface);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: var(--CFD-theme-System-OnSurface);
  border-bottom: 1px solid var(--CFD-theme-System-OutlineVariant);
}

.header {
  display: flex;
  flex-direction: row;
  background-color: inherit;
  overflow-y: scroll;
}

.header::-webkit-scrollbar {
  display: none;
}

.header {
  scrollbar-width: none;
  -ms-overflow-style: none;
}