.form {
  box-sizing: border-box;
  padding: 24px 16px 24px 16px;
  border-radius: 6px;
  background-color: var(--CFD-theme-Surface-3);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.label {
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

@media screen and (max-width: 1239px) {
  .form {
    padding: 16px;
    gap: 16px;
  }
}