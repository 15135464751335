.tooltipTitle {
    color: var(--CFD-theme-System-OnSurfaceVariant);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; 
    letter-spacing: 0.1px;
    margin-bottom: 4px;
    margin-top: 0;
}

.tooltipTextContainer {
    display: flex;
    flex-direction: column;
}

.tooltipText {
    color: var(--CFD-theme-System-OnSurfaceVariant);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    letter-spacing: 0.25px;
    margin-bottom: 4px;
    margin-top: 0;
}

.tooltipBtnContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.smallTooltiText {
    color: var(--CFD-theme-System-Inverseonsurface);
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; 
    letter-spacing: 0.5px;
    width: fit-content;
    margin-top: 0;
    margin-bottom: 0;
}