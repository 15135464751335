.tickerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  min-height: 48px;
  position: relative;
  box-sizing: border-box;
}

.tickerWrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  position: relative;
  animation: ticker linear infinite;
}

.iconContainer {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  z-index: 2;
}

.icon {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 12px;
}

.tickerMessage {
  display: inline-block;
  font-family: Arial;
  white-space: nowrap;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}

@keyframes ticker {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media (max-width: 600px) {
  .tickerMessage {
    font-size: 12px;
  }
}

.tickerWrapper > .tickerMessage {
  min-width: 100%;
  flex-shrink: 0;
}

.tickerWrapper::after {
  content: '';
  flex-shrink: 0;
}

