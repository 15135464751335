.sectionWrapper {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 20px;
}

.redemptionAmountContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.detailsWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.detailsText {
  margin-top: 4px;
  margin-bottom: 4px;
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-OnSurface);
}

.detailsText1 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}

.summaryContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 904px) {
  .sectionWrapper {
    overflow: scroll;
    height: calc(100dvh - 160px);
  }
}