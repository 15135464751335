.emptyContainer {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.emptyContainer span {
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
}

.emptyContainer span:first-of-type {
    color: var(--CFD-theme-System-OnSurface);
    margin-top: 16px;
    margin-bottom: 4px;
}

.emptyContainer span:last-of-type {
    color: var(--CFD-theme-System-OnSurfaceVariant);
}

.symbolCell {
    display: flex;
    align-items: center;
}

.symbolCell span {
    margin-left: 6px;
    white-space: nowrap;
    color: var(--CFD-theme-System-OnSurface);
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
}

.typeLabelBuy {
    max-height: 24px;
    display: flex;
    align-items: center;
    padding: 4px 12px;
    color: var(--CFD-theme-Custom-Green);
    background-color: var(--CFD-theme-State-layers-on-green-container-opacity_12);
    text-align: center;
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    border-radius: 8px;
    justify-content: center;
}

.typeLabelSell {
    max-height: 24px;
    display: flex;
    align-items: center;
    padding: 4px 12px;
    color: var( --CFD-theme-Custom-Red);
    background-color: var(--CFD-theme-State-layers-red-opacity_12);
    text-align: center;
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    border-radius: 8px;
    justify-content: center;
}

.loaderContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 80px 0;
    position: absolute;
    margin: 0 auto;
}

@keyframes fadeInEffect {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .fadeIn {
    animation: fadeInEffect 1s ease-in-out forwards;
  }


