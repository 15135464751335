.filePickerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  width: 40px;
}


.hidenDocsPicker {
  opacity: 0;
  height: 0;
  width: 0;
  line-height: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.addFileBtn {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: space-evenly;
  flex: row;
  align-items: center;
  background-color: inherit;
  outline: none;
  border: none;
  color: var(--CFD-theme-System-Tertiary);
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .filePickerContainer {
    align-items: flex-start;
    width: 30px;
  }
}