.transferWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 24px;
  gap: 16px;
  height: calc(100vh - 121px - 97px);
  max-height: 300px;
}

.transferInfoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
}

.transferInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 130px;
  background-color: var(--CFD-theme-State-layers-surface-variant-opacity_16);
  border-radius: 6px;
}

.transferInfoTitle {
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-Outline);
}

.amountContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.fromAmount {
  font-family: Arial;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: var(--CFD-theme-System-OnSurface);
}

.toAmount {
  text-align: center;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-Outline);
}

.rateWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 8px;
}

.rateTitle {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--CFD-theme-System-Outline);
}

.rateText {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-Outline);
}

.rateAmountContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.fromRate {
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}

.toRate {
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: var(--CFD-theme-System-Primary);
}

.footerWrapper {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 16px;
  gap: 16px;
  width: 100%;
  background-color: var(--CFD-theme-Surface-2);
  border-top: 1px solid var(--CFD-theme-System-OutlineVariant);
}

.btnWrapper {
  width: 100%;
}