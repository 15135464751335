.header {
    padding: 32px;
    display: flex;
    align-items: center;
    margin-bottom: 36px;
}

@media screen and (max-width: 1239px) {
    .header {
        background-color: var(--CFD-theme-Surface-4);
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 32px;
        padding-right: 32px;
        margin-bottom: 0;
    }
}