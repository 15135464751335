.dialogContent {
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
    width: 100%;
}

.sectionContainer {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sectionTitle {
    font-family: Arial;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    width: 100%;
    text-align: center;
    letter-spacing: 0.15px;
    color: var(--CFD-theme-System-OnSurface);
}

.sectionText {
    margin-top: 24px;
    margin-bottom: 0;
    color: var(--CFD-theme-System-OnSurfaceVariant);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
    max-width: 440px;
    width: 100%;
    text-align: center;
}
