.sectionWrapper {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 18px;
}

.dialogAutoTraider {
  color: var(--CFD-theme-System-OnSurface);
  font-family: Arial;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-left: 6px;
  margin-top: 24px;
}

.toggle, .riskLevel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 6px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
  font-size: 14px;
  letter-spacing: 0.1px;
  border-bottom: 1px solid var(--CFD-theme-State-layers-outline-opacity_16);
}

.riskLevel {
  flex-direction: column;
}

.riskLevel_p {
  margin-bottom: 16px;
}

.levels {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
  gap: 12px;
}

.textRisk {
  background-color: #E1E2E9;
  border-radius: 6px;
  padding: 14px 16px;
  letter-spacing: 0.25px;
  line-height: 20px;
}

.spanHr {
  margin-left: -24px;
  border-top: 1px solid var(--CFD-theme-State-layers-outline-opacity_16);
  width: 112%;
}

@media screen and (max-width: 904px) {
  .sectionWrapper {
    overflow: scroll;
    max-height: calc(100dvh - 232px);
    justify-content: space-between;
  }

  .riskLevel {
    border-bottom: 0;
    margin-bottom: 24px;
  }

  .blockTextRisk {
    padding: 18px 0;
  }

  .sectionAverage {
    height: 120px;
    flex-grow: 1;
  }
}

@media screen and (max-width: 400px) {
  .sectionWrapper {
    max-height: calc(100dvh - 212px);
  }

  .level {
    gap: 4px;
  }
}

@media screen and (max-height: 780px) {
  .textRisk {
    height: 100px;
    overflow: auto;
    font-size: 14px;
  }
}

