.wrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--CFD-theme-System-Surface);
  border: 1px solid var(--CFD-theme-System-OutlineVariant);
  color: var(--CFD-theme-System-OnSurface);
  border-radius: 6px;
  min-height: 522px;
  height: 100%;
}

.title {
  color: var(--CFD-theme-System-OnSurface);
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  padding: 16px 16px 0 16px;
}

.content {
  padding: 5px 0px;
}
