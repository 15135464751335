.datePickerWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.datePickerWrapper span {
  padding: 5px 10px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.4px;
}
