.languageContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.language {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.languageText {
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-OnSurface);
}

.languageIcon {
  border-radius: 20px;
  width: 25px;
  height: 25px;
}