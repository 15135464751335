.mainWrapper {
  width: 100%;
  height: calc(100% - 120px);
}

.bodywrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.noDataRow {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100dvh;
}
