.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.dialogWrapper {
  max-width: 100%;
  max-height: 100%; 
  background-color: var(--CFD-theme-Surface-1);
  padding: 0;
  border: none;
  margin: auto;
  z-index: 1001;
  display: flex; 
  justify-content: center;
  align-items: center; 
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.30), 0px 6px 10px 4px rgba(0, 0, 0, 0.15);
  overflow: hidden; 
}

.image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; 
}