.footerWrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
  align-items: center;
  padding-bottom: 24px;
}

.footerAgreement {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
}

.footerAgreementText {
  color: var(--CFD-theme-System-OnSurfaceVariant);
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.footerAgreementLink {
  margin-left: 5px;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-decoration: none;
  color: var(--CFD-theme-System-Tertiary);
}

.footerBtnContainer {
  width: 100%;
}

.btnsWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 16px;
}

.btnContinue {
  width: 100%;
}

.btnView {
  width: 100%;
  color: var(--CFD-theme-System-OnTertiary);
  text-align: center;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.btnTxt {
  text-align: center;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
}

@media screen and (max-width: 904px) {
  .footerWrapper {
    background-color: var(--CFD-theme-Surface-2);
    padding: 24px;
    border-top: 1px solid var(--CFD-theme-System-OutlineVariant);
    position: fixed;
    bottom: 0;
  }
}

@media screen and (max-width: 600px) {
  .btnsWrapper {
    flex-direction: column;
  }
}