.mainWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.bodywrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--CFD-theme-System-Surface);
}
