.footerWrapper {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--CFD-theme-System-Surface);
  box-sizing: border-box;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 32px;
  padding-right: 32px;
  z-index: 2;
}

.footerInfoWrapper {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.footerInfoCell {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.footerInfoTitle {
  color: var(--CFD-theme-System-Outline);
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: 0.5px;
}

.footerInfoValue {
  color: var(--CFD-theme-System-OnSurfaceVariant);
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: 0.5px;
}

.footerTitle {
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-Outline);
}

.footerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}

.menuContainer {
  display: flex;
  flex-direction: row;
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-Outline);
}

.chatBtn {
  width: 78px;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: var(--CFD-theme-System-SecondaryContainer);
  padding-left: 10;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 8px;
  gap: 8px;
  cursor: pointer;
}

.chatContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.chatText {
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-OnSecondaryContainer);
}

.dot {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 50%;
}

.mobileFooterWrapper {
  display: none;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--CFD-theme-Surface-2);
}

.mobileBtnContainer {
  background-color: transparent;
  outline: none;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
}

.mobileNavCell {
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

.mobileNavTitle {
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: 0.5px;
  text-align: center;
}

.menuItemImageWrapper {
  border-radius: 25px;
  cursor: pointer;
  height: 24px;
  width: 24px;
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
}

.footerTitleContainer {
  padding-left: 80px;
  gap: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  width: auto;
}

.footerTitleEmail {
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-Outline);
  cursor: pointer;
}

.selectedMenuImageItem {
  background: var(--CFD-theme-System-SecondaryContainer);
}

.menuItemImageWrapper:hover {
  background: var(--CFD-theme-State-layers-on-surface-opacity_08);
}

.menuLink {
  cursor: pointer;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}

.chatCell {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chatMobileBtn {
  position: relative;
  background-color: transparent;
  outline: none;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chatbtnText {
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: 0.5px;
  text-align: center;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}

.badgeWrapper {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  top: 2px;
  right: 18px;
  max-width: 24px;
  max-height: 24px;
  min-width: 16px;
  min-height: 16px;
  background-color: var(--CFD-theme-System-Error);
  border-radius: 50%;
}

.badgeWrapperText {
  margin-top: 0;
  margin-bottom: 0;
  color: var(--CFD-theme-System-OnError);;
  text-align: center;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; 
  letter-spacing: 0.3px;
}

.iconWrapper {
  border-radius: 25px;
  cursor: pointer;
  height: 24px;
  width: 24px;
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
}

.iconWrapperChecked {
  background-color: var(--CFD-theme-State-layers-on-surface-opacity_08);
}

.fakeFooter {
  height: 200px;
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  background-color: var(--CFD-theme-Surface-1);
  z-index: 1000;
}

@media screen and (max-width: 1200px) {
  .footerInfoCell {
    flex-direction: column;
    gap: 0;
  }
}

@media screen and (max-width: 905px) {
  .footerInfoCell {
    display: none;
  }
}

@media screen and (max-width: 599px) {
  .footerWrapper {
    display: none;
  }
  .mobileFooterWrapper {
    height: auto;
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 8px;
    z-index: 100;
  }
}

@media screen and (max-width: 343px) {
  .chatbtnText {
    line-height: 10px; 
  }
}

