
.profileCompiled {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
}
.completeTitle {
  width: auto;
  height: 22px;
  text-align: left;
  padding-left: 36px;
  font-size: 16px;
  margin-top: 20px;
}
.completeProgress {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 30px;
  margin-bottom: 10px;
}

.progressBarLine {
  width: 100%;
}

.verifyIconBlock {
  margin: 0 10px;
}

.verifyIconWrapper {
  background: var(--CFD-theme-System-Tertiary);
  color: var(--CFD-theme-System-OnTertiary);
  padding: 2px;
  height: 12px;
  width: 12px;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
}

