.container {
    width: 58.75%;
    min-height: calc(100vh - 68px - 140px);
    height: fit-content;
    border-radius: 16px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
    background-color: var(--CFD-theme-Surface-1);
    margin: 0 auto;
    margin-bottom: 140px;
}

.containerHeader {
    background-color: var(--CFD-theme-Surface-4);
    padding: 32px 32px 24px 32px;
    border-radius: 16px 16px 0 0;
    text-align: start;
}

.containerHeader h3 {
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    color: var(--CFD-theme-System-OnSurfaceVariant);
}

.containerHeaderActions {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
}

.containerHeaderActions h2 {
    font-family: Arial;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    color: var(--CFD-theme-System-OnSurface);
}

.containerHeaderActions .containerHeaderActions__row {
    display: flex;
    align-items: center;
}

.containerHeaderActions .containerHeaderActions__row button {
    max-width: 101px;
}

.containerHeaderActions .containerHeaderActions__row span {
    margin-right: 16px;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: var(--CFD-theme-System-OnSurfaceVariant);
}

.containerBody {
    padding-top: 64px;
    display: flex;
    align-items: center;
}

@media screen and (max-width: 1239px) {
    .container {
        width: 100%;
        min-height: 100vh;
        margin-bottom: 0;
        border-radius: 0;
    }

    .containerHeader {
        padding: 24px 32px 24px 32px;
        border-top: 1px solid var(--CFD-theme-System-Outline);
        border-radius: 0;
    }

    .containerHeader h3 {
        display: none;
    }

}

@media screen and (max-width: 600px) {
    .containerHeader {
        padding: 24px 16px 24px 16px;
    }
    .containerHeaderActions {
        flex-direction: column;
    }
}