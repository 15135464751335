.subHeaderWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding-top: 8px;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: var(--CFD-theme-System-Surface);
}

.subHeaderTitle {
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}

.svg {
  height: 16px;
  width: 18px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}

.optionsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
}

.options{
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.datePickerContainer {
  width: 400px;
}

.datePickerSelect {
  width: 250px;
}

.btnContainer {
  width: 60px;
  height: 43px;
}