.wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

}

.infoSign {
  width: 24px;
  height: 24px;
}

.titleMessage {
  color: var(--CFD-theme-System-OnSurfaceVariant);
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
}

.cryptoContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
}