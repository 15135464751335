.dialogFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 24px;
    box-sizing: border-box;
    width: 100%;
    background-color: var(--CFD-theme-Surface-3);
}

.dialogFooterSupportWindow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
    max-width: 460px;
    width: 100%;
    height: 70px;
    background-color: var(--CFD-theme-System-Inversesurface);
    border-radius: 6px;
}

.dialogFooterSupportText {
    margin-left: 5px;
    color: var(--CFD-theme-System-Inverseonsurface);
}

.dialogFooterButton {
    width: 100%;
    margin-top: 32px;
    max-width: 470px;
}

@media screen and (max-width: 904px) {
    .dialogFooter {
        padding: 0 16px 10px 16px;
        position: fixed;
        bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
    }
    .dialogFooterButton {
        max-width: unset;
    }
    .dialogFooterSupportWindow {
        max-width: unset;
    }
}
