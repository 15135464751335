.assetsCheckContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
} 

.assetsCheckContainerTitle {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}

.bankName {
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}

.subTitleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.assetsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
}

.ibanSwift {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.ibanSwiftTitle {
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-Outline);
}

.ibanSwiftContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}

.ibanSwiftText {
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}

.visaContainer {
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.cardIcons {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100px;
}

.iconContainer {
  height: 54px;
  width: 24px;
  display: flex;
  align-items: flex-start;
}

.titleWrapper {
  display: flex;
  flex-direction: row;
}

.title {
  font-family: Arial;
  color: var(--CFD-theme-System-OnSurface);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.binWrapper {
  height: 54px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.binBtn {
  display: flex;
  align-items: center;
  outline: none;
  background-color: transparent;
  border: none;
  height: 28px;
  width: 28px;
  cursor: pointer;
  border-radius: 100px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
  padding: 0;
  justify-content: center;
}

.binIconColor {
  width: 20px;
  height: 20px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}


.binBtn:hover {
  background-color: var(--CFD-theme-State-layers-on-surface-opacity_16);
}

.uncheckedSpan {
  width: 24px;
  height: 24px;
}

@media screen and (max-width: 600px) {
  .ibanSwiftContainer {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
  .ibanSwiftTitle {
    letter-spacing: 0;
  }
  .ibanSwiftText {
    letter-spacing: 0;
  }
}

.dialogHeader {
  padding: 5px;
  text-align: center;
  font-size: 18px;
  color: var(--CFD-theme-System-OnSurface);
  font-family: Arial;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; 
}

.dialogFooter {
  display: flex;
  padding: 5px;
  flex-direction: row;
  justify-content: space-evenly;
}

.dialogBody {
  display: flex;
  flex-direction: row;
  padding: 5px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  width: 277px;
}
