.modal {
  width: 280px;
  background-color: #262C42;
  padding: 24px;
  border-radius: 8px;
  text-align: center;
  color: #E3E1E9;
}

.modalContent {
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.heading {
  font-family: Arial;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  margin: 0;
  margin-bottom: 27px;
}

.subHeading {
  font-family: Arial;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
  margin-bottom: 4px;
}

.inputContainer {
  display: flex;
  justify-content: space-between;
  margin: 0;
}

.digitInput {
  width: 34px;
  height: 36px;
  text-align: center;
  font-size: 18px;
  color: #E3E1E9;
  background-color: transparent;
  border: 1px solid #BBBFD0;
  border-radius: 4px;
}

.digitInput:focus {
  outline: none;
  border-color: #9BD4A0;
}

.prompt {
  font-family: Arial;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
  color: #BBBFD0;
  margin: 0;
  margin-top: 2px;
  margin-bottom: 30px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 6px;
}

.cancelButtonWrapper {
  width: 94px;
  height: 38px;
}

.deleteButtonWrapper {
  width: 120px;
}
