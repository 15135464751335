.cellTextBtnIcon {
  outline: none;
  border: none;
  background-color: transparent;
  text-align: center;
  margin-top: 5px;
  padding: 0;
}

.tableHeadersTitleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  width: 100%;
}

.tableHeadersCenterTitleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  width: 100%;
}

.tableHeaderslastTitleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  width: 100%;
}

.tableHeadersTitle {
  margin-bottom: 0;
  color: var(--CFD-theme-System-OnSurfaceVariant);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
}

.cellContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.tableHeadersStatusWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  width: 100%;
}
