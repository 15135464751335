.dialogHeader {
    display: flex;
    height: 72px;
    width: 100%;
    align-items: center;
    border-top-left-radius: 11px;
    border-top-right-radius: 11px;
    background-color: var(--CFD-theme-Surface-4);
    border-bottom: 1px solid var(--CFD-theme-System-OutlineVariant);
}

.dialogHeaderContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    height: 100%;
}

.dialogHeaderContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.dialogHeaderTitle {
    margin-top: 12px;
    margin-bottom: 0;
    margin-left: 24px;
    color: var(--CFD-theme-System-OnSurface);
    font-family: Arial;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; 
}

.dialogHeaderBtnContainer {
    margin-right: 29px;
}

.dialogHeaderText {
    margin-bottom: 0;
    margin-top: 5px;
    margin-left: 24px;
    color: var(--CFD-theme-System-OnSurface);
    font-family: Arial;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.5px;
}

.dialogHeaderTotalSteps {
    color: var(--CFD-theme-System-OnSurfaceVariant);
    font-family: Arial;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.5px;
    opacity: 0.38;
}

@media screen and (max-width: 904px) {
    .dialogHeader {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        background-color: var(--CFD-theme-Surface-2);
    }
}

@media screen and (max-width: 500px) {
    .dialogHeaderTitle {
        margin-top: 8px;
    }
}
