.pageWrapper {
    width: 100%;
    min-height: 100vh;

    font-family: Arial;

    background-color: var(--CFD-theme-Surface-4);
    color: var(--CFD-theme-System-OnSurfaceVariant);
}

.buttonWrapper {
    width: 170px;
    height: 40px;
}

.contentWrapper {
    margin-top: 130px;
    margin-bottom: 130px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 56px;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header {
    width: 100%;
    box-sizing: border-box;
    padding: 32px;
    margin-bottom: 36px;
}

.contentText {
    font-family: Arial;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
}

@media screen and (max-width: 650px) {
    .notFoundImage {
        width: 320px;
    }

    .contentWrapper {
        margin-top: 20%;
        margin-bottom: 20%;
    }

    .contentText {
        font-size: 24px;
        text-align: center;
    }
}