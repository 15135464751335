.bodyLiveChat {
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  min-height: 350px;
  overflow: scroll;
  position: relative;
}

.messageWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
}

.messageInfoContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.iconWrapper {
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: row;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: var(--CFD-theme-System-Secondary);
}

.icontitle {
  text-align: center;
  font-family: Roboto;
  font-size: 9.6px;
  font-style: normal;
  font-weight: 500;
  line-height: 14.4px; 
  letter-spacing: 0.06px;
  color: var(--CFD-theme-System-OnSecondary)
}

.infoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.infoContent {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-OnSurfaceVariant);

}

.messageReciveContainer {
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 16px;
  padding-left: 16px;
  width: 224px;
  margin-bottom: 12px;
  background-color: var(--CFD-theme-Surface-3);
}

.messageReciveText {
  overflow-wrap: break-word;
  word-break: break-all;    
  white-space: normal; 
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.1px;
  color: var(--CFD-theme-System-OnSurface) !important;
}

.messageSendContainer  {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 12px;
}

.messageSend {
  margin-bottom: 12px;
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 40px;
  padding-left: 16px;
  width: 224px;
  background-color: var(--CFD-theme-System-PrimaryContainer);
} 

.messageSendText {
  overflow-wrap: break-word;
  word-break: break-all;    
  white-space: normal; 
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.1px;
  color: var(--CFD-theme-System-OnSurface) !important;
}

.messageSendStatus {
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
  text-align: end;
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-Outline);
}

.messageContainer {
  display: flex;
  flex-direction: column;
}

.messageIconContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.fileText {
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.1px;
  color: var(--CFD-theme-System-OnSurface);
  cursor: pointer;
}

.fileText:hover {
  opacity: 0.7;
}

@media screen and (max-width: 600px) {
  .bodyLiveChat {
    position: static;
    padding-top: 80px;
  }
}