.header {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  padding: 8px 8px 8px 24px;
  background-color: var(--CFD-theme-Surface-1);
  border-bottom: 1px solid var(--CFD-theme-System-OutlineVariant);
}

.contentWrapper {
  display: flex;
  height: 48px;
  width: 100%;
  gap: 16px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.contentTitle {
  font-family: Arial;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: var(--CFD-theme-System-OnSurface);
}

.btn {
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
}

@media screen and (max-width: 599px) {
  .header {
    padding: 8px 4px 8px 16px;
  }

  .contentWrapper {
    gap: 4px;
  }

  .contentTitle {
    font-size: 22px;
    line-height: 28px;
  }

  .btn {
    padding: 0px 8px;
  }
}