.tableContainer {
  box-sizing: border-box;
  padding: 0 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--CFD-theme-Surface-3);
  border-radius: 6px;
  min-height: 72px;
}

.tableContainer:hover {
  background-color: var(--CFD-theme-Surface-5);
}

.assetContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding-right: 10px;
  min-width: 200px;
}

.assetContainerParagraph {
  margin-left: 12px;
  color: var(--CFD-theme-System-OnSurface);
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.tipsParagraph {
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}

.tipsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.btnContainer {
  width: 100%;
  max-width: 150px;
}

.scrollContainer {
  display: flex;

}


@media screen and (max-width: 1239px) {
  .tableContainer {
    padding: 16px;
    flex-direction: column;
  }

  .tipsContainer {
    max-width: 100vw;
    width: 100%;
    gap: 10px;
    overflow: scroll;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .btnContainer {
    width: 150px;
  }

  .mobileCellHeader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-bottom: 24px;
  }

  .mobileOptionsWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .mobileOptionsContainer {
    display: flex;
    gap: 8px;
    max-width: 75vw;
    min-width: 70vw;
    width: 100%;
    max-width: 100px;
    overflow-x: scroll;
    padding-bottom: 10px;
  }

  .mobileOptionAssetWrapper {
    min-width: 80px;
    border-radius: 6px;
    box-sizing: border-box;
    padding: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 700px) {
  .mobileOptionsContainer {
    max-width: 68vw;
    min-width: 65vw;
  }
}

@media screen and (max-width: 655px) {
  .mobileOptionsContainer {
    max-width: 65vw;
  }
}

@media screen and (max-width: 599px) {
  .mobileOptionsContainer {
    max-width: 83vw;
  }
}

@media screen and (max-width: 562px) {
  .mobileOptionsContainer {
    max-width: 80vw;
    min-width: 50vw;
  }
}

@media screen and (max-width: 478px) {
  .mobileOptionsContainer {
    max-width: 78vw;
  }
}

@media screen and (max-width: 435px) {
  .mobileOptionsContainer {
    max-width: 75vw;
  }
}

@media screen and (max-width: 383px) {
  .mobileOptionsContainer {
    max-width: 73vw;
  }
}

@media screen and (max-width: 353px) {
  .mobileOptionsContainer {
    max-width: 71vw;
  }
}

