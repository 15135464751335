.wrapper {
  display: flex;
  padding: 10px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  box-sizing: border-box;
  position: fixed;
  left: 80px;
  right: 0;
  z-index: 8;
  height: 52px;
  top: 0;
}

.wrapper-dark {
  background: linear-gradient(0deg, var(--CFD-Theme-State-layers-surface-variant-opacity_08, rgba(62, 65, 79, 0.08)) 0%, var(--CFD-Theme-State-layers-surface-variant-opacity_08, rgba(62, 65, 79, 0.08)) 100%), var(--CFD-Theme-System-Surface, #14192D);
}

.wrapper-light {
  background: linear-gradient(0deg, var(--State-layers-surface-variant-opacity_08, rgba(202, 207, 228, 0.08)) 0%, var(--State-layers-surface-variant-opacity_08, rgba(202, 207, 228, 0.08)) 100%), var(--System-Surface, #F2F2F2);
}

.header-menu-wrapper {
  display: flex;
  align-items: center;
  gap: 32px;
}

.header-menu {
  display: flex;
  align-items: center;
  gap: 56px;
}

.header-menu-item {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.header-menu-item-label {
  color: var(--CFD-theme-System-OnSurfaceVariant);

  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.headerTitle {
  color: var(--CFD-theme-System-Primary);
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  letter-spacing: 0.15px;
}

.headerLiveMobileLogoBtn {
  outline: none;
  border: none;
  background-color: transparent;
}

.header-menu-item-value {
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  width: fit-content;
  min-width: 75px;
}

.header-user-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  padding: 2px 2px 2px 14px;
  border-radius: 100px;
}

.header-user-wrapper:hover {
  background-color: var(--CFD-theme-State-layers-on-surface-variant-opacity_08);
}

.user-info-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
}

.user-info-wallet {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--CFD-theme-Custom-Green);
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1px;
  text-align: left;
}

.user-info-name {
  color: var(--CFD-theme-System-OnSurface);
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  white-space: nowrap;
}

.userIcon {
  height: 40px;
  min-height: 40px;
  width: 40px;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;
  text-transform: capitalize;
  color: var(--CFD-theme-System-OnPrimary);
  background-color: var(--CFD-theme-System-Primary);
  border-radius: 50%;
}

.accountInfoWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  gap: 16px;
  padding: 6px 16px;
  border-radius: 6px;
}

.svg {
  color: var(--CFD-theme-System-Primary);
  width: 20px;
  height: 20px;
}

.accountInfoBallanceWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  box-sizing: border-box;
  border-right: 1px solid var(--CFD-theme-Surface-5);
  padding-right: 16px;
  width: 100%;
}

.accountInfoBallance {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}

.accountInfoBallanceBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  color: var(--CFD-theme-System-Outline);
  cursor: pointer;
}

.accountInfoBallanceText {
  color: var(--CFD-theme-System-Outline);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
}

.accountInfoBallanceAmount {
  color: var(--CFD-theme-System-OnSurface);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  white-space: nowrap;
}

.accountInfo {
  display: flex;
  flex-direction: column;
}

.accountInfoTitle {
  color: var(--CFD-theme-System-Outline);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: 0.5px;
}

.accountInfoAmount {
  color: var(--CFD-theme-System-OnSurfaceVariant);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: 0.4px;
  white-space: nowrap;
}

.infoWrapperSelect {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.infoWrapperSelectBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  outline: none;
  border: none;
  background-color: var(--CFD-theme-Surface-3);
  padding: 8px 12px;
  border-radius: 100px;
  cursor: pointer;
  transition: background-color 0.3s ease, height 0.3s ease;
}

.infoWrapperSelectBtn:hover {
  background-color: var(--CFD-theme-State-layers-secondary-opacity_08);
}

.infoWrapperSelectTitle {
  color: var(--CFD-theme-System-Secondary);
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  white-space: nowrap;
  padding-left: 8px;
}

.infoWrapperSelectBtnContent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.infoWrapperSelectBalance {
  display: none;
}

.infoWrapperSelectIconWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.infoWrapperSelectIconMobile {
  display: none;
}

@media screen and (max-width: 1239px) {
  .header-menu {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .user-info-name {
    display: none;
  }

  .header-user-wrapper {
    gap: 0;
    padding: 0;
  }
}

@media screen and (max-width: 900px) {
  .infoWrapperSelectBalance {
    display: flex;
    color: var(--CFD-theme-System-Secondary);
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.5px;
    padding-left: 14px;
    width: 100%;
  }
  .infoWrapperSelectBtnContent {
    flex-direction: column;
  }
  .infoWrapperSelectBtn {
    padding: 4px 12px;
  }

  .accountInfo {
    display: none;
  }

  .accountInfoBallanceWrapper {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .header-user-wrapper {
    gap: unset;
  }
  .accountInfoWrapper {
    padding: 6px;
  }
}

@media screen and (max-width: 599px) {
  .infoWrapperSelectIconWrapper {
    display: none;
  }
  .infoWrapperSelectIconMobile {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}


@media screen and (max-width: 450px) {
  .user-info-wallet {
    display: none;
  }
  .headerTitle {
    display: none;
  }
}

.headerLiveMobileLogo {
  display: none;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
}

.headerLiveMobileOptions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
}

.headerLiveMobileBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media screen and (max-width: 599px) {
  .wrapper {
    left: 0;
    padding-left: 12px;
    padding-right: 12px;
    gap: 6px;
  }
  .headerLiveMobileLogo {
    display: flex;
    width: auto;
    padding-right: 4px;
    width: 42px;
    height: 36px;
  }
  .headerLiveMobileOptions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 6px;
    width: 100%;
  }
}