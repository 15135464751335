.sectionStep2 {
    max-width: 500px;
    width: 100%;
    padding-top: 16px;
    display: flex;
    flex-direction: column;
}

.sectionStep2Title {
    padding-left: 24px;
    margin-top: 6px;
    color: var(--CFD-theme-System-OnSurface);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
}

.sectionStep2Text {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 24px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; 
    letter-spacing: 0.4px;
    color: var(--CFD-theme-System-OnSurface);
}

@media screen and (max-width: 904px) {
    .sectionStep2 {
        max-width: unset;
        gap: 14px;
    }
}