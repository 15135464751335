.section {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
}

.detailsWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.detailsText {
  margin-top: 4px;
  margin-bottom: 4px;
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-OnSurface);
}

.detailsText1 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-OnSurface);
}

.detailsCreated {
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.wallet {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  width: 100%;
  padding-left: 8px;
  border-radius: 8px;
  background-color: var(--CFD-theme-System-SecondaryContainer);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
} 
