.currencyInfoContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.currencyIcon {
  width: 20px;
  height: 20px; 
}

.currencyIconInput {
  width: 20px;
  height: 20px; 
  margin-right: 8px;
}