.accardionMainWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 10px 16px;
  box-sizing: border-box;
}

.accardionMainText {
  color: var(--CFD-theme-System-OnSurface);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  margin-bottom: 0;
}

.accardionContentWrapper {
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  gap: 8px;
}

.accardionContentRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
}

.accardionContentRowTitle {
  color: var(--CFD-theme-System-Outline);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
}

.accardionContentRowText {
  color: var(--CFD-theme-System-OnSurfaceVariant);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: 0.4px;
}