.transferWrapper  {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}

.transferForm  {
  width: 100%;
}

.label {
  color: var(--CFD-theme-System-OnSurfaceVariant);
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  letter-spacing: 0.15px;
  margin-bottom: 10px;
}

.changeBtn {
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.footerWrapper {
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.rateContainer {
  display: flex;
  flex-direction: column;
}

.rate {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.rateFrom {
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}

.rateTo {
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  letter-spacing: 0.15px;
  color: var(--CFD-theme-System-Primary);
}

.feeContainer {
  color: var(--CFD-theme-System-Outline);
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: 0.5px;
}

