.settingsSecondSectionContentContainer {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.settingsSecondSectionContentText {
  text-align: start;
  color: var(--CFD-theme-System-OnSurfaceVariant);
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.settingsSecondSectionContentBtnContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  box-sizing: border-box;
}

.btnSecondContainer {
  width: auto;
  min-width: 120px;
}