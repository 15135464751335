.customStepNumberIconActive {
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    color: var(--CFD-theme-System-OnTertiary);
}

.customStepNumberIconUnActive {
    color: var(--CFD-theme-System-Surface);
    text-align: center;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}

.steperWrapper {
    margin-top: -64px;
    width: 100%;
}