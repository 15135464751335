.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
}

.noDataText {
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--CFD-theme-System-OnSurface);
}

.noFoundText {
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: 0.4px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}