.dialogFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 32px;
    width: 100%;
    box-sizing: border-box;
}

.dialogFooterButton {
    max-width: 470px;
    width: 100%;
}

@media screen and (max-width: 904px) {
    .dialogFooter {
        padding-left: 16px;
        padding-right: 16px;
        position: fixed;
        bottom: 0;
    }
    .dialogFooterButton {
        max-width: unset;
    }
}