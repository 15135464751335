.tableWrapper {
  border: 1px solid var(--CFD-theme-State-layers-outline-opacity_12);
  border-radius: 6px;
  background-color: var(--CFD-theme-System-Surface);
}

.section  {
  padding-top: 16px;
  padding-bottom: 12px;
}

.table {
  overflow: hidden;
  transition: max-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 0.3s ease-out;
  max-height: 0;
  opacity: 0;
}

.expanded {
  max-height: 100%;
  opacity: 1;
}

@media screen and (max-width: 599px) {
  .tableWrapper {
    border-top: none;
    border-left: none;
    border-right: none;
  }
  .section {
    padding-bottom: 24px;
  }
}