.successWrapper {
  display: flex;
  flex-direction: column;
  padding: 24px;
  box-sizing: border-box;
  width: 100%;
  overflow: auto;
  max-height: calc(100vh - 178px);
}

.successSummary {
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--CFD-theme-System-OutlineVariant);
  width: 100%;
  gap: 10px;
}

.successSummary > :first-child {
  margin-top: 20px;
}

.successSummary > :last-child {
  margin-top: 8px;
}

.summuryContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.summaryLabel {
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}

.summaryValue {
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-OnSurface);
}

.footerWrapper {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 16px;
  gap: 16px;
  width: 100%;
  background-color: var(--CFD-theme-Surface-2);
  border-top: 1px solid var(--CFD-theme-System-OutlineVariant);
}

.btnWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.btnContainer {
  width: 100%;
}

@media screen and (max-width: 599px) {
  .successWrapper {
    max-height: calc(100dvh - 130px);
  }
}
