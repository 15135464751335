.inputContainer {
    padding-left: 24px;
    padding-right: 24px;
    background-color: inherit;
    min-height: 70px;
    box-sizing: border-box;
}

.inputTitle {
    margin-top: 0;
    margin-bottom: 4px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; 
    letter-spacing: 0.4px;
    color: var(--CFD-theme-System-OnSurface);
}

.inputCellContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    box-sizing: border-box;
}

.inputCell {
    background-color: inherit;
    margin-right: 8px;
    margin-left: 2px;
    width: 34px;
    height: 34px;
    font-size: 20px;
    text-align: center;
    border-radius: 4px;
    color: var(--CFD-theme-System-Outline);
    border: 2px solid var(--CFD-theme-System-Outline); 
    outline: none;
}

.inputCell:focus {
    border: 2px solid var(--CFD-theme-System-Primary);
}

@media screen and (max-width: 600px) {
    .inputContainer {
        min-height: 100px;
    }
}