.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.row {
    height: 100%;
    display: flex;
    align-items: center;
    overflow-x: auto;
    width: 95%;
}

.row .assetChip {
    border-radius: 8px;
    border: 1px solid var(--CFD-theme-System-Outline);
    padding: 6px 8px 6px 16px;
    display: flex;
    align-items: center;
    margin-right: 12px;
    max-height: 32px;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.row .selected {
    border: unset;
    background-color: var(--CFD-theme-System-SecondaryContainer);
}

.row .assetChip button {
    outline: none;
    border: none;
    background-color: transparent;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0;
    padding: 0;
}

.row .assetChip:hover {
    background-color: var(--CFD-theme-System-SecondaryContainer);
}

.row .assetChip button svg, .row .assetChip svg {
    width: 18px;
    height: 18px;
}

.row .assetChip span {
    color: var(--CFD-theme-System-OnSurfaceVariant);
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
    margin: 0 8px;
    cursor: pointer;
    white-space: nowrap;
}

.row .assetChip:last-of-type {
    margin-right: 0;
}

.toggleBtn {
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.inputRow {
    display: flex;
    align-items: center;
    width: 100%;
}

.inputRow label {
    font-family: Arial;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: var(--CFD-theme-System-OnSurface);
}

.menuHeaderText {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: var(--CFD-theme-System-OnSurfaceVariant)
}

@media screen and (max-width: 1280px) {
    .toggleBtn {
        display: none 
    }
}
