.dialogContent {
    padding-left: 24px;
    padding-right: 24px;
}

.dialogContentTopContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
    margin-top: 16px;
}

.dialogContentTitle {
    color: var(--CFD-theme-System-OnSurface);
    margin-left: 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-top: 0;
    margin-bottom: 0;
}

.dialogContentTextContainer {
    display: flex;
    width: 100%;
}

.dialogContentText {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--CFD-theme-System-OnSurface);
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.5px;
}

.dialogContentBottomContainer {
    width: 100%;
    min-height: 150px;
    margin-top: 29px;
}

.dialogContentBottomFileSizeText {
    color: var(--CFD-theme-System-Outline);
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.5px;
    margin-bottom: 0;
}

.dialogUploadFilesWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
}

.dialogUploadFilesTitle {
    color: var(--CFD-theme-System-OnSurfaceVariant);
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.4px;
}

.dialogUploadFiles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
    border: 1px solid var(--CFD-theme-System-OutlineVariant);
    border-radius: 4px;
    margin-bottom: 16px;
}

.dialogUploadFilesInfo {
    display: flex;
    flex-direction: column;
}

.dialogUploadFilesInfoName {
    color: var(--CFD-theme-System-Tertiary);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; 
    letter-spacing: 0.4px;
    margin-bottom: 4px;
    margin-top: 4px;
    min-height: 18px;
}

.dialogUploadFilesInfoTime {
    margin-top: 0;
    margin-bottom: 4px;
    color: var(--CFD-theme-System-OnSurfaceVariant);
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; 
    letter-spacing: 0.5px;
}

.dialogUploadFilesBinBtn {
    background-color: inherit;
    width: 40px;
    height: 40px;
    outline: none;
    border: none;
}

@media screen and (max-width: 904px) {
    .dialogContent {
        overflow: scroll;
        max-height: calc(100dvh - 195px);
    }
}
