.wrapper {
    display: flex;
    align-items: center;
    border-radius: 12px;
    background: var(--CFD-theme-Surface-5);
}

.container {
    padding: 15px;
    width: 100%;
    overflow: hidden;
}

.container .time {
    color: var(--CFD-theme-System-OnSurfaceVariant);
    font-family: Arial;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.5px;
}

.container .title {
    margin: 4px 0;
    color: var(--CFD-theme-System-OnSurface);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.container .content {
    color: var(--CFD-theme-System-OnSurface);
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.25px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    line-height: 1.5em;
    height: 3em;
}

.imageWrapper {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imageWrapper img {
    border-radius: 0 6px 6px 0;
}