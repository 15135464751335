.wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 16px 16px 0 16px;
  width: 100%;
  height: auto;
  gap: 24px;
  background: var(--CFD-theme-System-Surface);
}

.settingsContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: calc(50% - 11px);
  border-radius: 6px;
  border: 1px solid var(--CFD-theme-System-OutlineVariant);
  background-color: var(--CFD-theme-System-Surface);
}

.profileContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--CFD-theme-System-Surface);
  width: calc(50% - 11px);
  gap: 24px;
}

.statisticContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  min-height: 276px;
  width: 100%;
}

.tradingChartContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 240px;
}

.balanceChartContainer {
  min-height: 336px;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .wrapper {
    flex-direction: column;
    padding-bottom: 16px;
  }

  .settingsContainer {
    width: 100%;
  }

  .profileContainer {
    width: 100%;
  }
}