.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px 16px;
  width: 100%;
  box-sizing: border-box;
}

.termsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  box-sizing: border-box;
}

.termsOptions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.termsOptionsTitle {
  color: var(--CFD-theme-System-OnSurfaceVariant);
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.termsOptionsBtnContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.termsStatusContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  gap: 8px;
  background-color: var(--CFD-theme-State-layers-secondary-container-opacity_08);
  border-radius: 4px;
}

.termsStatusTitle {
  color: var(--CFD-theme-System-OnSurfaceVariant);
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.statusLabelContainer {
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 4px;
}

.statusLabel {
  text-align: center;
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
}

.btn { 
  height: 32px;
  width: 32px;
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  border-radius: 100px;
  cursor: pointer;
}

.btn:hover {
  background-color: var(--CFD-theme-State-layers-on-surface-opacity_16);
}

@media screen and (max-width: 600px) {
  .termsOptions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .termsOptionsBtnContainer {
    width: 100%;
  }
}
