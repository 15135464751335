.invoiceHeader {
  font-size: 18px;
  color: #1A1C21;
  font-weight: 700;
  padding-left: 12px;
}

.invoiceWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px 80px;
  background: #F9FAFC;
  width: 595px;
  height: 1040px;
  position: absolute;
  left: -9999px;
}

.invoiceInfoWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  border-radius: 12px;
  padding: 16px 16px;
  border: 0.5px solid #D7DAE0;
  background: #FFF;
}

.invoiceInfoHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.invoiceInfoElement {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.gray-bold {
  color: #5E6470;
  font-weight: 500;
}

.black-bold {
  color: #1A1C21;
  font-weight: 600;
}

.font-14 {
  font-size: 10px;
}

.font-8 {
  font-size: 8px;
}

.blue-color {
  color: #4E5B92;
  font-weight: 700;
}

.font-20 {
  font-size: 20px;
}

.text-right {
  text-align: right;
}

.paymentDetailsWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  padding-block: 16px 45px;
  border-top: 0.5px solid #D7DAE0;
}

.paymentDetailsInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 343px;
}

.paymentDetailsInfoElement {
  word-wrap: break-word;
}

.descriptionDetailsWrapper {
  display: flex;
  flex-direction: column;
}

.descriptionDetailsInfoHeading {
  display: flex;
  justify-content: space-between;
  border-top: 0.5px solid #D7DAE0;
  border-bottom: 0.5px solid #D7DAE0;
  padding-block: 8px 8px;
  
  color: #5E6470;
  font-size: 8px;
  font-weight: 400;
  text-transform: uppercase;
}

.desciptionDetailsAmount {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid #D7DAE0;
  padding-block: 12px 12px;
  font-size: 10px;
  color: #1A1C21;
  font-weight: 400;
}

.subtotal {
  display: flex;
  justify-content: flex-end;
  color: #1A1C21;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  padding-block: 12px 0;
}

.mediumWidth {
  display: flex;
  justify-content: space-between;
  width: 303px;
  padding-bottom: 12px;
}

.border-b {
  border-bottom: 0.5px solid #D7DAE0;
}

.total {
  display: flex;
  justify-content: flex-end;
  padding-top: 12px;
}

.invoiceInfoElement {
  color: #1A1C21;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
}