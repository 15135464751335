.dialogContent {
    padding-left: 24px;
    padding-right: 24px;
}

.dialogContentTop {
    margin-top: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
    letter-spacing: 0.15px;
}

.dialogContentTopText {
    margin-bottom: 8px;
    color: var(--CFD-theme-System-OnSurface);
}

.dialogContentTopText2 {
    margin-top: 27px;
    color: var(--CFD-theme-System-OnSurface);
}

.dialogContentTopTextContent {
    display: flex;
    flex-direction: row;
}

.dialogContentTopTextAndroid {
    display: flex;
    justify-content: space-between;
    width: 105px;
    margin-top: 7px;
    color: var(--CFD-theme-System-Tertiary);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    letter-spacing: 0.1px;
}

.dialogContentTopTextIOS {
    display: flex;
    justify-content: space-between;
    width: 80px;
    margin-top: 8px;
    margin-left: 10px;
    color: var(--CFD-theme-System-Tertiary);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    letter-spacing: 0.1px;
}

.dialogContentText2 {
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: var(--CFD-theme-System-OnSurface);
}

.dialogContentMiddle {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.inputWrapper {
    position: relative;
}


.input {
    max-width: 440px;
    width: 100%;
    height: 40px;
    padding-left: 10px;
    background-color: var(--CFD-theme-System-SurfaceVariant);
    border-radius: 12px;
    border: none;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
    letter-spacing: 0.5px;
    color: var(--CFD-theme-System-OnSurfaceVariant);
    cursor: not-allowed;
}

.copyIcon {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
}

.qrContainer {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 24px;
    width: 100%;
    height: auto;
}

.qr {
    width: 160px;
    height: 160px;
}

.dialogContentText3 {
    margin-top: 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
    letter-spacing: 0.15px;
    color: var(--CFD-theme-System-OnSurface);
}

@media screen and (max-width: 904px) {
    .dialogContent {
        overflow: scroll;
        margin-bottom: 0;
        max-height: calc(100dvh - 224px);
    }
    .input {
        max-width: unset;
    }
}



