.headerWrapper {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  color: var(--CFD-theme-System-OnSurface);
  background-color: var(--CFD-theme-System-Surface);
}

.header {
  display: flex;
  box-sizing: border-box;
  border-radius: 6px;
  gap: 24px;
  width: 100%;
  padding: 24px;
  background-color: var(--CFD-theme-Surface-3);
}

.svg {
  color: var(--CFD-theme-System-Primary);
  width: 20px;
  height: 20px;
}

.optionsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.optionsStatusContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.status {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.statusText {
  color: var(--CFD-theme-System-OnSurface);
  text-align: center;
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 6px;
  background: var(--CFD-theme-State-layers-on-surface-opacity_08);
  padding: 4px 12px;
}

.selectedAccount {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  width: auto;
  border-radius: 6px;
  background-color: var(--CFD-theme-State-layers-green-opacity_12);
}

.selectedAccountText {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: 0.4px;
  color: var(--CFD-theme-Custom-Green);
}

.optionsBtnsContainer {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.optionsBtnsWrapper {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.depositBtnsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 100px;
  padding: 8px 12px 8px 8px;
  gap: 6px;
  background-color: var(--CFD-theme-Surface-5);
}

.btn:hover {
  background-color: var(--CFD-theme-State-layers-primary-opacity_08);
}

.btnText {
  color: var(--CFD-theme-System-Primary);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.withdrawBtnsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.balanceContainer {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
}


.balanceText {
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.1px;
  color: var(--CFD-theme-System-Outline);
}

.selectBtn {
  border: 1px solid var(--CFD-theme-Surface-5);
  outline: none;
  background-color: transparent;
  color: var(--CFD-theme-System-Secondary);
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  border-radius: 6px;
  padding: 4px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.selectBtn:hover {
  background-color: var(--CFD-theme-State-layers-on-surface-variant-opacity_08);
}

.sumContainer {
  height: 32px;
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 9px;
} 

.sumText {
  font-family: Arial;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  color: var(--CFD-theme-System-OnSurface);
}

.options {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  gap: 16px;
}

.optionsLine {
  display: flex;
  flex-direction: row;
}

.credit {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
}

.invested {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
}

.creditInvestedText {
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.1px;
  color: var(--CFD-theme-System-Outline);
}

.amountCreditInvestedText {
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.1px;
  color: var(--CFD-theme-System-OnSurface);
  white-space: nowrap;
}

.transferBtnsContainer {
  display: none;
}

.line {
  display: none;
}

@media screen and (max-width: 1239px) {
  .transferBtnsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
}


@media screen and (max-width: 905px) {
  .header {
    flex-direction: column;
  }
  .optionsBtnsContainer {
    gap: 32px;
    flex-direction: column-reverse;
  }

  .optionsBtnsWrapper {
    justify-content: space-evenly;  
    width: 100%;
    gap: 8px;
  }

  .line {
    display: inline;
    width: 100%;
    margin: 0;
    border: 0;
    height: 2px;
    background-color: var(--CFD-theme-System-OutlineVariant);
  }

  .options {
    justify-content: flex-start;
  }

  .optionsLine {
    display: none;
  }

  .depositBtnsContainer {
    width: 100%;
  }

  .withdrawBtnsContainer {
    width: 100%
  }

  .transferBtnsContainer {
    width: 100%;
  }

  .btn {
    width: 100%;
    justify-content: center;
  }
}

@media screen and (max-width: 599px) {
  .header {
    padding-top: 0;
    padding-bottom: 0;
    gap: 16px; 
    background-color: var(--CFD-theme-System-Surface);
  }

  .headerWrapper {
    padding: 16px 0 24px 0;
  }

  .optionsStatusContainer {
    justify-content: space-between;
  }

}

@media screen and (max-width: 400px) {
  .options {
    gap: 6px;
  }

  .btnText {
    font-size: 14px;
  }

  .sumText {
    font-size: 20px;
  }
}
