.profileSettingsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    align-items: center;
    gap: 24px;
    padding-top: 16px;
    padding-bottom: 8px;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    align-items: center;
}

.profileContainer {
    display: flex;
    flex-direction: column;
    height: 64px;
    width: 100%;
    gap: 2px;
    align-items: flex-start;
}

.profileTitle {
    width: 130px;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; 
    letter-spacing: 0.4px;
    color: var(--CFD-theme-System-OnSurfaceVariant);
}

.profileTitlePhoneNumberDisable {
    opacity: 0.5;
}

.profileInputContainer {
    width: 100%;
    min-width: 360px;
}


.profileSettingsFooter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.profileSettingsFooterBtns {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.mainBtnContainer {
    height: 40px;
    width: 100%;
    max-width: 360px
}

.deleteBtnContainer {
    width: 149px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.deleteBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.5;
    cursor: pointer;
    background-color: inherit;
    outline: none;
    border: none;
}

.deleteBtnSpan {
    color: var(--CFD-theme-System-OnSurface);
    opacity: 0.5;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    letter-spacing: 0.1px;
    margin-left: 8px;
}

@media screen and (max-width: 1239px) {
    .profileContainer {
        flex-direction: column;
        margin-bottom: 10px;
    }
    .profileTitle {
        max-width: 330px;
        width: 100%;
    }
    .profileSettingsFooterBtns {
        flex-direction: column; 
    }
}

@media screen and (max-width: 425px) {
    .profileInputContainer {
        min-width: 280px;
    }
}