.inputSendMessage {
  box-sizing: border-box;
  min-width: 200px;
  width: 100%;
  background-color: transparent;
  outline: none;
  border: none;
  color: var(--CFD-theme-System-OnSurface);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  letter-spacing: 0.5px;
}

.footerBtn {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  height: 48px;
  width: 48px;
}
