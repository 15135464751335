.tradeMobileWrapper {
  display: flex;
  flex-direction: column;
  padding: 0 16px 0 16px;
  box-sizing: border-box;
  gap: 24px;
  width: 100%;
}

.tradeModeWrapperHeaderButtons {
  display: flex;
  gap: 12px;
  align-items: center;
}

.tradeModeWrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-content: center;
  height: 100%;
  max-height: 100vh;
  width: 100vw;
  z-index: 10;
  background-color: var(--CFD-theme-System-Surface);
  overflow: auto;
}

.tradeModeWrapperHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  padding: 12px 8px 12px 24px;
  gap: 4px;
}

.tradeModeWrapperHeaderTitle {
  color: var(--CFD-theme-System-OnSurface);
  font-family: Arial;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; 
}

.crossBtn {
  width: 48px;
  height: 48px;
  outline: none;
  border: none;
  background-color: transparent;
}

.tradeModeMarketWrapper {
  width: 100%;
  display: flex;
}

.tradeModeChartWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 560px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding-left: 1px;
  padding-right: 1px;
  padding-bottom: 155px;
}

.tradeModeTable {
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  height: auto;
  background-color: var(--CFD-theme-Surface-1);
  padding-bottom: 20px;
}

.tradeModeBtnContainer {
  display: flex;
  position: fixed;
  bottom: 40px;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  gap: 16px;
  padding: 10px 20px 10px 20px;
  width: 100%;
  height: 60px;
  background-color: var(--CFD-theme-Surface-2);
}

.marketTitle {
  font-size: 24px;
  padding-left: 16px;
}

.tradeModeFooter  {
  display: flex;
  flex-direction: column;
}

