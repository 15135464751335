.mainContainer {
    width: 100vw;
    height: 100vh;
    background-color: var(--CFD-theme-System-SurfaceVariant);
    background-image: url('../../../../assets/images/mask.svg');
    overflow-y: auto;
}

.gradientWrapper {
    overflow-y: auto;
    width: 100vw;
    height: 100vh;
    background: radial-gradient(147.49% 99.46% at 80.49% 0%, rgba(255, 0, 0, 0.03) 0%, rgba(130, 0, 0, 0.00) 100%), radial-gradient(15.99% 84.94% at 101.48% 59.44%, rgba(137, 245, 86, 0.07) 0%, rgba(108, 245, 86, 0.00) 100%), radial-gradient(47.17% 60.34% at 81.84% 68.49%, rgba(74, 212, 86, 0.09) 0%, rgba(0, 255, 10, 0.00) 100%), radial-gradient(127.47% 84.85% at 88.14% 50%, rgba(0, 43, 255, 0.03) 0%, rgba(0, 43, 255, 0.00) 100%), radial-gradient(81.96% 107.6% at 100% 104.77%, rgba(0, 43, 255, 0.03) 0%, rgba(254, 0, 61, 0.00) 99.99%, rgba(0, 43, 255, 0.00) 100%), radial-gradient(28.79% 45.9% at 80.36% 69.89%, rgba(86, 113, 245, 0.05) 0%, rgba(137, 245, 86, 0.05) 0.01%, rgba(86, 113, 245, 0.00) 100%);
}