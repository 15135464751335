.sectionWrapper {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 24px;
}

.redemptionAmountContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
}

.redemptionAmountTitle {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.25px;
  color: var(--CFD-theme-System-Outline);
}

.redemptionAmount {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.1px;
  color: var(--CFD-theme-System-OnSurface);
}


.redemptiomToContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 16px;
}

.redemptiomToTitle {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.25px;
  color: var(--CFD-theme-System-Outline);
}

.redemptiomToContentContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  width: 100%;
  margin-bottom: 24px;
}

.wallet {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  padding-left: 8px;
  border-radius: 8px;
  background-color: var(--CFD-theme-System-SecondaryContainer);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
} 

.amountContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}

.walletAmount {
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.25px;
  color: var(--CFD-theme-System-Outline);
}

.infoIconContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.redemptiomInfo {
  margin-top: 8px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 14px;
  border-radius: 4px;
  background-color: var(--CFD-theme-System-Inversesurface);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
}

.selectWalletInfoContentText {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.25px;
  color: var(--CFD-theme-System-Inverseonsurface);
}

.amountWalletContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.detailsWrapper {
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--CFD-theme-System-OutlineVariant);
}

.details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.detailsText {
  margin-top: 4px;
  margin-bottom: 4px;
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-OnSurface);
}

.detailsText1 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-OnSurface);
}

.detailsCreated {
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.wallet {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  width: 100%;
  padding-left: 8px;
  border-radius: 8px;
  background-color: var(--CFD-theme-System-SecondaryContainer);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
} 

@media screen and (max-width: 905px) {
  .sectionWrapper {
    overflow: scroll;
    max-height: calc(100dvh - 207px);
  }
}

@media screen and (max-width: 429px) {
  .redemptiomToContentContainer {
    flex-direction: column;
    align-items: flex-start;
  }
}
