.currencyContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.currencyInfoContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.currencyTitle {
  margin-bottom: 0;
}

.currencyIcon {
  width: 20px;
  height: 20px; 
}