.wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.dotsWrapper {
  display: flex;
  flex-direction: row;
}

.contentWrapper {
  position: absolute;
  display: flex;
  flex-direction: row;
}

.label {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}

.value {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-OnSurface);
}


.dot {
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--CFD-theme-System-Tertiary);
}

.lineBody {
  position: absolute;
  top: 9px;
  left: 4px;
  border: 1px solid var(--CFD-theme-State-layers-on-surface-opacity_08);
}

.lineFill {
  position: absolute;
  top: 8px;
  left: 4px;
  height: 100%;
  border: 1px solid var(--CFD-theme-System-Tertiary);
}
