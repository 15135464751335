.liveChatWrapper {
  position: fixed;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  right: 24px;
  width: 320px;
  max-height: 500px;
  z-index: 10;
  border-radius: 6px;
  background-color: var(--CFD-theme-Surface-2);
  box-shadow: 0px 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px 0px rgba(0, 0, 0, 0.30);
}

.footerLiveChat {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 60px;
  background-color: var(--CFD-theme-Surface-4);
  gap: 4px;
  padding: 12px;
  align-items: center;
  box-sizing: border-box;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.inputWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .liveChatWrapper {
    width: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    min-height: 100dvh;
    height: 100%;
    background-color: var(--CFD-theme-Surface-2);
    box-sizing: border-box;
    padding-top: 64px;
    padding-bottom: 100px;
    z-index: 10;
  }
  .footerLiveChat {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;
    position: fixed;
    bottom: 64px;
    right: 0;
  }
}
