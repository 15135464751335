.subHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
  box-sizing: border-box;
}

.subHeaderTitle {
  color: var(--CFD-theme-System-Outline);
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  letter-spacing: 0.5px;
  margin-bottom: 14px;
}

.subHeaderOptions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.assetsCheckContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
} 

.assetsCheckContainerTitle {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}

.assetsCheckContainerTitle.active {
  color: var(--CFD-theme-System-Primary)
}

.radioBtnWrapper {
  border-radius: 6px;
  margin-left: 0;
  margin-right: 0;
  padding-right: 20px;
  max-width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 12px 20px 12px 16px;
  gap: 12px;
  box-sizing: border-box;
  transition: background-color 0.3s ease;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
}

.radioBtnWrapper:hover {
  background-color: var(--CFD-theme-State-layers-on-surface-variant-opacity_08);
}

.radioBtnWrapper.active {
  background-color: var(--CFD-theme-State-layers-on-surface-variant-opacity_08);
}

@media screen and (max-width: 904px) {
  .subHeader {
    padding: 16px;
  }
  .radioBtnWrapper {
    max-width: unset;
  }
}
