.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 16px;
    overflow: scroll;
}

.cardWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 16px;
    padding-bottom: 16px;
    width: 100%;
    gap: 16px;
}

.cardWrapperText {
    margin-top: 0;
    margin-bottom: 0;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: var(--CFD-theme-System-OnSurface);
}

.input {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid var(--CFD-theme-System-Outline);
    border-radius: 4px;
    box-sizing: border-box;
}

.footer {
    position: fixed;
    bottom: 0;
    box-sizing: border-box;
    width: 530px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    background-color: var(--CFD-theme-Surface-1);
    border-top: 1px solid var(--CFD-theme-System-OutlineVariant);
}

.btnContainer {
    width: 100%;
}

.btnText {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    text-align: center;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
}

@media screen and (max-width: 904px) {
    .wrapper {
        padding-bottom: 46px;
        padding-left: 16px;
        padding-right: 16px;
    }
    .footer {
        padding: 24px 16px 24px 16px;
    }
}

@media screen and (max-width: 599px) {
    .wrapper {
        padding-bottom: 80px;
    }
    .footer {
        width: 100%;
    }
}