.menuHeader {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 16px;
  width: 100%;
  height: 72px;
  background-color: var(--CFD-theme-Surface-2);
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  border-bottom: 1px solid var(--CFD-theme-System-OutlineVariant);
}

.btnContainer {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

.menuWrapper {
  width: 360px;
  min-height: 360px;
  max-width: 360px;
  box-sizing: border-box;
  position: fixed;
  border-radius: 14px;
  right: 16px;
  background-color: var(--CFD-theme-Surface-2);
  z-index: 10;
  box-shadow: 0px 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px 0px rgba(0, 0, 0, 0.30);
}


.headerTitle {
  font-family: Arial;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: var(--CFD-theme-System-OnSurface);
}

.menuBodyLanguage {
  box-sizing: border-box;
  padding-left: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: var(--CFD-theme-Surface-2);
  max-height: 230px;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  gap: 12px;
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 8px 16px 8px 16px;
  width: 100%;
  background-color: var(--CFD-theme-Surface-2);
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
}

.btnFooterContainer {
  width: 100%;
}


@media screen and (max-width: 600px) {
  .menuHeader {
    position: fixed;
    top: 0;
    z-index: 12;
    border-bottom: 1px solid var(--CFD-theme-System-OutlineVariant);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .menuWrapper {
    position: fixed;
    width: 100%;
    top: 0;
    right: 0;
    min-height: 100dvh;
    min-width: 100%;
  }

  .menuBodyLanguage {
    overflow: auto;
    min-height: 100dvh;
    padding-bottom: 80px;
    padding-top: 80px;
  }

  .footer {
    position: fixed;
    bottom: 0;
    z-index: 12;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  } 
}