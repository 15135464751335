.cellTextBtnIcon {
  outline: none;
  border: none;
  background-color: transparent;
  text-align: center;
  margin-top: 5px;
  padding: 0;
}

.tableHeadersTitleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  width: 100%;
}

.tableHeadersTitle {
  margin-bottom: 0;
  color: var(--CFD-theme-System-OnSurfaceVariant);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
}

.cellContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.paddingLeft {
  padding-left: 10%;
}

.tableHeadersStatusWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  width: 100%;
}