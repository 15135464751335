.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    gap: 24px;
    overflow: scroll;
}

.label {
    margin-left: 16px;
    color: var(--CFD-theme-System-OnSurfaceVariant);
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.4px;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
}

.formContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    align-items: flex-start;
    justify-content: center;
}

@media screen and (max-width: 904px) {
    .wrapper {
        height: auto;
    }
}