.inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  position: relative;
}

.supportingText {
  margin-top: 2px;
  margin-bottom: 0;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: start;
  color: var(--CFD-theme-System-OnSurfaceVariant);
  position: absolute;
  top: 42px;
  left: 16px;
}

.disabled {
  opacity: 0.3;
}

.supportingTextError {
  margin-top: 2px;
  margin-bottom: 0;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: start;
  color: var(--CFD-theme-System-Error);
  position: absolute;
  top: 42px;
  left: 16px;
}

.inputTitle {
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.4px;
  text-align: start;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}


