.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 24px;
}

.headerTitle {
  width: 400px;
  font-family: Arial;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  color: var(--CFD-theme-System-OnSurface);
}