.containerHeaderActions__row {
    display: flex;
    align-items: center;
}

.containerHeaderActions__row span {
    white-space: nowrap;
    margin-right: 16px;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: var(--CFD-theme-System-OnSurfaceVariant);
}

.containerBody__column {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding-left: 32px;
    padding-right: 32px;
    box-sizing: border-box;
}

.containerBody__column label {
    width: 250px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: var(--CFD-theme-System-OnSurfaceVariant);
    text-align: start;

}

.containerBody {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.inputContainer {
    height: 40px;
    width: 360px;
}
.emptyBoxLeft {
    width: 250px;
}

.emptyBox {
    min-width: 150px;
}

.containerBodyBtn {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
}

.containerBtn {
    height: 40px;
    width: 360px;
}

@media screen and (max-width: 1239px) {
    .containerBody__column {
        gap: 32px;
    }
}

@media screen and (max-width: 905px) {
    .containerBody {
        flex-direction: column;
        justify-content: center;
    } 
    .containerBodyBtn {
        flex-direction: column;
        justify-content: center;
    }
    .emptyBox {
        display: none;
    }
    .containerBody__column label {
        width: 360px;
    }
}

@media screen and (max-width: 600px) {
    .inputContainer {
        width: 100%;
    }

    .containerBody__column label {
        width: 100%;
    }
    .containerBtn {
        width: 100%;
    }
    .containerHeaderActions__row {
        justify-content: space-between;
    }
    .containerHeaderActions__row span {
        margin-right: 5px;
    }
}