.wrapper {
  padding: 0 16px 16px 16px;
  box-sizing: border-box;
}

.accountContainer {
  display: flex;
  width: 100%;
  padding-left: 12px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.accountCurrencyTitle {
  margin-bottom: 0;
}

.accountCurrencyIcon {
  width: 20px;
  height: 20px; 
}

.svg {
  height: 16px;
  width: 16px;
}