html {
  scrollbar-width: thin; 
  scrollbar-color: var(--CFD-theme-System-OutlineVariant) transparent; 
  background-color: var(--CFD-theme-System-Surface);
  
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  };

  ::-webkit-scrollbar-track {
    background: transparent; 
  };

  ::-webkit-scrollbar-thumb {
    background: var(--CFD-theme-System-OutlineVariant);
    border-radius: 4px;
  };

  ::-webkit-scrollbar-thumb:hover {
    background: var(--CFD-theme-System-OutlineVariant);
  };

}

body {
  margin: 0;
  box-sizing: border-box;
  font-family: 'Arial Regular', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

p {
  margin: 0;
}

ul {
  padding: 0;
  box-sizing: border-box;
  margin: 0;
}

a {
  text-decoration: unset;
}

.invoiceHeader {
  font-size: 18px;
  color: #1A1C21;
  font-weight: 700;
  padding-left: 12px;
}

.invoiceWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px 80px;
  background: #F9FAFC;
  width: 595px;
  height: 1040px;
  position: absolute;
  left: -9999px;
}

.invoiceInfoWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  border-radius: 12px;
  padding: 16px 16px;
  border: 0.5px solid #D7DAE0;
  background: #FFF;
}

.invoiceInfoHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.invoiceInfoElement {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.gray-bold {
  color: #5E6470;
  font-weight: 500;
}

.black-bold {
  color: #1A1C21;
  font-weight: 600;
}

.font-14 {
  font-size: 10px;
}

.font-8 {
  font-size: 8px;
}

.blue-color {
  color: #4E5B92;
  font-weight: 700;
}

.font-20 {
  font-size: 20px;
}

.text-right {
  text-align: right;
}

.paymentDetailsWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  padding-block: 16px 45px;
  border-top: 0.5px solid #D7DAE0;
}

.paymentDetailsInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 343px;
}

.paymentDetailsInfoElement {
  height: 14px;
}

.descriptionDetailsWrapper {
  display: flex;
  flex-direction: column;
}

.descriptionDetailsInfoHeading {
  display: flex;
  justify-content: space-between;
  border-top: 0.5px solid #D7DAE0;
  border-bottom: 0.5px solid #D7DAE0;
  padding-block: 8px 8px;
  
  color: #5E6470;
  font-size: 8px;
  font-weight: 400;
  text-transform: uppercase;
}

.desciptionDetailsAmount {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid #D7DAE0;
  padding-block: 12px 12px;
  font-size: 10px;
  color: #1A1C21;
  font-weight: 400;
}

.subtotal {
  display: flex;
  justify-content: flex-end;
  color: #1A1C21;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  padding-block: 12px 0;
}

.mediumWidth {
  display: flex;
  justify-content: space-between;
  width: 303px;
  padding-bottom: 12px;
}

.border-b {
  border-bottom: 0.5px solid #D7DAE0;
}

.total {
  display: flex;
  justify-content: flex-end;
  padding-top: 12px;
}

.invoiceInfoElement {
  color: #1A1C21;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
}

html[data-theme='dark'] {
  --common-background-color: #292929;
  --common-color: #ffffff;

  --CFD-theme-Custom-Green: #57D570;
  --CFD-theme-Custom-GreenContainer: #004F20;
  --CFD-theme-Custom-OnGreen: #002611;
  --CFD-theme-Custom-OnGreenContainer: #4CF886;
  --CFD-theme-Custom-OnRed: #1C0A0C;
  --CFD-theme-Custom-OnRedContainer: #FFD9DE;
  --CFD-theme-Custom-OnRurple: #181878;
  --CFD-theme-Custom-OnRurpleContainer: #DEDEFF;
  --CFD-theme-Custom-Red: #FF616A;
  --CFD-theme-Custom-RedContainer: #8F002B;
  --CFD-theme-Custom-Purple: #BABAFF;
  --CFD-theme-Custom-PurpleContainer: #313190;
  --CFD-theme-State-layers-background-opacity_08: rgba(18, 19, 24, 0.08);
  --CFD-theme-State-layers-background-opacity_12: rgba(18, 19, 24, 0.12);
  --CFD-theme-State-layers-background-opacity_16: rgba(18, 19, 24, 0.16);
  --CFD-theme-State-layers-error-container-opacity_08: rgba(147, 0, 10, 0.08);
  --CFD-theme-State-layers-error-container-opacity_12: rgba(147, 0, 10, 0.12);
  --CFD-theme-State-layers-error-container-opacity_16: rgba(147, 0, 10, 0.16);
  --CFD-theme-State-layers-error-opacity_08: rgba(255, 180, 171, 0.08);
  --CFD-theme-State-layers-error-opacity_12: rgba(255, 180, 171, 0.12);
  --CFD-theme-State-layers-error-opacity_16: rgba(255, 180, 171, 0.16);
  --CFD-theme-State-layers-green-container-opacity_08: rgba(0, 79, 32, 0.08);
  --CFD-theme-State-layers-green-container-opacity_12: rgba(0, 79, 32, 0.12);
  --CFD-theme-State-layers-green-container-opacity_16: rgba(0, 79, 32, 0.16);
  --CFD-theme-State-layers-green-opacity_08: rgba(87, 213, 112, 0.08);
  --CFD-theme-State-layers-green-opacity_12: rgba(87, 213, 112, 0.12);
  --CFD-theme-State-layers-green-opacity_16: rgba(87, 213, 112, 0.16);
  --CFD-theme-State-layers-green-opacity_38: rgba(87, 213, 112, 0.38);
  --CFD-theme-State-layers-inverse-on-surface-opacity_08: rgba(44, 46, 54, 0.08);
  --CFD-theme-State-layers-inverse-on-surface-opacity_12: rgba(44, 46, 54, 0.12);
  --CFD-theme-State-layers-inverse-on-surface-opacity_16: rgba(44, 46, 54, 0.16);
  --CFD-theme-State-layers-inverse-primary-opacity_08: rgba(78, 91, 146, 0.08);
  --CFD-theme-State-layers-inverse-primary-opacity_12: rgba(78, 91, 146, 0.12);
  --CFD-theme-State-layers-inverse-primary-opacity_16: rgba(78, 91, 146, 0.16);
  --CFD-theme-State-layers-inverse-surface-opacity_08: rgba(227, 225, 233, 0.08);
  --CFD-theme-State-layers-inverse-surface-opacity_12: rgba(227, 225, 233, 0.12);
  --CFD-theme-State-layers-inverse-surface-opacity_16: rgba(227, 225, 233, 0.16);
  --CFD-theme-State-layers-on-background-opacity_08: rgba(227, 225, 233, 0.08);
  --CFD-theme-State-layers-on-background-opacity_12: rgba(227, 225, 233, 0.12);
  --CFD-theme-State-layers-on-background-opacity_16: rgba(227, 225, 233, 0.16);
  --CFD-theme-State-layers-on-error-container-opacity_08: rgba(255, 218, 214, 0.08);
  --CFD-theme-State-layers-on-error-container-opacity_12: rgba(255, 218, 214, 0.12);
  --CFD-theme-State-layers-on-error-container-opacity_16: rgba(255, 218, 214, 0.16);
  --CFD-theme-State-layers-on-error-opacity_08: rgba(105, 0, 5, 0.08);
  --CFD-theme-State-layers-on-error-opacity_12: rgba(105, 0, 5, 0.12);
  --CFD-theme-State-layers-on-error-opacity_16: rgba(105, 0, 5, 0.16);
  --CFD-theme-State-layers-on-green-container-opacity_08: rgba(76, 248, 134, 0.08);
  --CFD-theme-State-layers-on-green-container-opacity_12: rgba(76, 248, 134, 0.12);
  --CFD-theme-State-layers-on-green-container-opacity_16: rgba(76, 248, 134, 0.16);
  --CFD-theme-State-layers-on-green-opacity_06: rgba(0, 38, 17, 0.16);
  --CFD-theme-State-layers-on-green-opacity_08: rgba(0, 38, 17, 0.08);
  --CFD-theme-State-layers-on-green-opacity_12: rgba(0, 38, 17, 0.12);
  --CFD-theme-State-layers-on-green-opacity_38: rgba(0, 38, 17, 0.38);
  --CFD-theme-State-layers-on-primary-container-opacity_08: rgba(220, 225, 255, 0.08);
  --CFD-theme-State-layers-on-primary-container-opacity_12: rgba(220, 225, 255, 0.12);
  --CFD-theme-State-layers-on-primary-container-opacity_16: rgba(220, 225, 255, 0.16);
  --CFD-theme-State-layers-on-primary-fixed-opacity_08: rgba(5, 22, 75, 0.08);
  --CFD-theme-State-layers-on-primary-fixed-opacity_12: rgba(5, 22, 75, 0.12);
  --CFD-theme-State-layers-on-primary-fixed-opacity_16: rgba(5, 22, 75, 0.16);
  --CFD-theme-State-layers-on-primary-fixed-variant-opacity_08: rgba(54, 68, 121, 0.08);
  --CFD-theme-State-layers-on-primary-fixed-variant-opacity_12: rgba(54, 68, 121, 0.12);
  --CFD-theme-State-layers-on-primary-fixed-variant-opacity_16: rgba(54, 68, 121, 0.16);
  --CFD-theme-State-layers-on-primary-opacity_08: rgba(30, 45, 97, 0.08);
  --CFD-theme-State-layers-on-primary-opacity_12: rgba(30, 45, 97, 0.12);
  --CFD-theme-State-layers-on-primary-opacity_16: rgba(30, 45, 97, 0.16);
  --CFD-theme-State-layers-on-purple-container-opacity_08: rgba(222, 222, 255, 0.08);
  --CFD-theme-State-layers-on-purple-container-opacity_12: rgba(222, 222, 255, 0.12);
  --CFD-theme-State-layers-on-purple-container-opacity_16: rgba(222, 222, 255, 0.16);
  --CFD-theme-State-layers-on-purple-opacity_08: rgba(24, 24, 120, 0.08);
  --CFD-theme-State-layers-on-purple-opacity_12: rgba(24, 24, 120, 0.12);
  --CFD-theme-State-layers-on-purple-opacity_16: rgba(24, 24, 120, 0.16);
  --CFD-theme-State-layers-on-red-container-opacity_08: rgba(255, 217, 222, 0.08);
  --CFD-theme-State-layers-on-red-container-opacity_12: rgba(255, 217, 222, 0.12);
  --CFD-theme-State-layers-on-red-container-opacity_16: rgba(255, 217, 222, 0.16);
  --CFD-theme-State-layers-on-red-opacity_08: rgba(28, 10, 12, 0.08);
  --CFD-theme-State-layers-on-red-opacity_12: rgba(28, 10, 12, 0.12);
  --CFD-theme-State-layers-on-red-opacity_16: rgba(28, 10, 12, 0.16);
  --CFD-theme-State-layers-on-red-opacity_38: rgba(28, 10, 12, 0.38);
  --CFD-theme-State-layers-on-secondary-container-opacity_08: rgba(222, 225, 249, 0.08);
  --CFD-theme-State-layers-on-secondary-container-opacity_12: rgba(222, 225, 249, 0.12);
  --CFD-theme-State-layers-on-secondary-container-opacity_16: rgba(222, 225, 249, 0.16);
  --CFD-theme-State-layers-on-secondary-fixed-opacity_08: rgba(23, 27, 44, 0.08);
  --CFD-theme-State-layers-on-secondary-fixed-opacity_12: rgba(23, 27, 44, 0.12);
  --CFD-theme-State-layers-on-secondary-fixed-opacity_16: rgba(23, 27, 44, 0.16);
  --CFD-theme-State-layers-on-secondary-opacity_08: rgba(43, 48, 66, 0.08);
  --CFD-theme-State-layers-on-secondary-opacity_12: rgba(43, 48, 66, 0.12);
  --CFD-theme-State-layers-on-secondary-opacity_16: rgba(43, 48, 66, 0.16);
  --CFD-theme-State-layers-on-surface-opacity_08: rgba(219, 214, 233, 0.08);
  --CFD-theme-State-layers-on-surface-opacity_12: rgba(219, 214, 233, 0.12);
  --CFD-theme-State-layers-on-surface-opacity_16: rgba(219, 214, 233, 0.16);
  --CFD-theme-State-layers-on-surface-variant-opacity_08: rgba(189, 187, 208, 0.08);
  --CFD-theme-State-layers-on-surface-variant-opacity_12: rgba(189, 187, 208, 0.12);
  --CFD-theme-State-layers-on-surface-variant-opacity_16: rgba(189, 187, 208, 0.16);
  --CFD-theme-State-layers-on-tertiary-container-opacity_08: rgba(182, 241, 187, 0.08);
  --CFD-theme-State-layers-on-tertiary-container-opacity_12: rgba(182, 241, 187, 0.12);
  --CFD-theme-State-layers-on-tertiary-container-opacity_16: rgba(182, 241, 187, 0.16);
  --CFD-theme-State-layers-on-tertiary-fixed-opacity_08: rgba(0, 33, 9, 0.08);
  --CFD-theme-State-layers-on-tertiary-fixed-opacity_12: rgba(0, 33, 9, 0.12);
  --CFD-theme-State-layers-on-tertiary-fixed-opacity_16: rgba(0, 33, 9, 0.16);
  --CFD-theme-State-layers-on-tertiary-fixed-variant-opacity_08: rgba(27, 81, 41, 0.08);
  --CFD-theme-State-layers-on-tertiary-fixed-variant-opacity_12: rgba(27, 81, 41, 0.12);
  --CFD-theme-State-layers-on-tertiary-fixed-variant-opacity_16: rgba(27, 81, 41, 0.16);
  --CFD-theme-State-layers-on-tertiary-opacity_08: rgba(0, 57, 21, 0.08);
  --CFD-theme-State-layers-on-tertiary-opacity_12: rgba(0, 57, 21, 0.12);
  --CFD-theme-State-layers-on-tertiary-opacity_16: rgba(0, 57, 21, 0.16);
  --CFD-theme-State-layers-onsecondaryfixedvariant-opacity_08: rgba(66, 70, 89, 0.08);
  --CFD-theme-State-layers-onsecondaryfixedvariant-opacity_12: rgba(66, 70, 89, 0.12);
  --CFD-theme-State-layers-onsecondaryfixedvariant-opacity_16: rgba(66, 70, 89, 0.16);
  --CFD-theme-State-layers-outline-opacity_08: rgba(144, 144, 154, 0.08);
  --CFD-theme-State-layers-outline-opacity_12: rgba(144, 144, 154, 0.12);
  --CFD-theme-State-layers-outline-opacity_16: rgba(144, 144, 154, 0.16);
  --CFD-theme-State-layers-outline-variant-opacity_08: rgba(65, 66, 79, 0.08);
  --CFD-theme-State-layers-outline-variant-opacity_12: rgba(65, 66, 79, 0.12);
  --CFD-theme-State-layers-outline-variant-opacity_16: rgba(65, 66, 79, 0.16);
  --CFD-theme-State-layers-primary-container-opacity_08: rgba(54, 68, 121, 0.08);
  --CFD-theme-State-layers-primary-container-opacity_12: rgba(54, 68, 121, 0.12);
  --CFD-theme-State-layers-primary-container-opacity_16: rgba(54, 68, 121, 0.16);
  --CFD-theme-State-layers-primary-fixed-dim-opacity_08: rgba(183, 196, 255, 0.08);
  --CFD-theme-State-layers-primary-fixed-dim-opacity_12: rgba(183, 196, 255, 0.12);
  --CFD-theme-State-layers-primary-fixed-dim-opacity_16: rgba(183, 196, 255, 0.16);
  --CFD-theme-State-layers-primary-fixed-opacity_08: rgba(220, 225, 255, 0.08);
  --CFD-theme-State-layers-primary-fixed-opacity_12: rgba(220, 225, 255, 0.12);
  --CFD-theme-State-layers-primary-fixed-opacity_16: rgba(220, 225, 255, 0.16);
  --CFD-theme-State-layers-primary-opacity_08: rgba(183, 196, 255, 0.08);
  --CFD-theme-State-layers-primary-opacity_12: rgba(183, 196, 255, 0.12);
  --CFD-theme-State-layers-primary-opacity_16: rgba(183, 196, 255, 0.16);
  --CFD-theme-State-layers-purple-container-opacity_08: rgba(49, 49, 144, 0.08);
  --CFD-theme-State-layers-purple-container-opacity_12: rgba(49, 49, 144, 0.12);
  --CFD-theme-State-layers-purple-container-opacity_16: rgba(49, 49, 144, 0.16);
  --CFD-theme-State-layers-purple-opacity_08: rgba(186, 186, 255, 0.08);
  --CFD-theme-State-layers-purple-opacity_12: rgba(186, 186, 255, 0.12);
  --CFD-theme-State-layers-purple-opacity_16: rgba(186, 186, 255, 0.16);
  --CFD-theme-State-layers-red-container-opacity_08: rgba(143, 0, 43, 0.08);
  --CFD-theme-State-layers-red-container-opacity_12: rgba(143, 0, 43, 0.12);
  --CFD-theme-State-layers-red-container-opacity_16: rgba(143, 0, 43, 0.16);
  --CFD-theme-State-layers-red-opacity_08: rgba(255, 97, 106, 0.08);
  --CFD-theme-State-layers-red-opacity_12: rgba(255, 97, 106, 0.12);
  --CFD-theme-State-layers-red-opacity_16: rgba(255, 97, 106, 0.16);
  --CFD-theme-State-layers-red-opacity_38: rgba(255, 97, 106, 0.38);
  --CFD-theme-State-layers-scrim-opacity_08: rgba(0, 0, 0, 0.08);
  --CFD-theme-State-layers-scrim-opacity_12: rgba(0, 0, 0, 0.12);
  --CFD-theme-State-layers-scrim-opacity_16: rgba(0, 0, 0, 0.16);
  --CFD-theme-State-layers-secondary-container-opacity_08: rgba(66, 70, 89, 0.08);
  --CFD-theme-State-layers-secondary-container-opacity_12: rgba(66, 70, 89, 0.12);
  --CFD-theme-State-layers-secondary-container-opacity_16: rgba(66, 70, 89, 0.16);
  --CFD-theme-State-layers-secondary-fixed-dim-opacity_08: rgba(194, 197, 221, 0.08);
  --CFD-theme-State-layers-secondary-fixed-dim-opacity_12: rgba(194, 197, 221, 0.12);
  --CFD-theme-State-layers-secondary-fixed-dim-opacity_16: rgba(194, 197, 221, 0.16);
  --CFD-theme-State-layers-secondary-fixed-opacity_08: rgba(222, 225, 249, 0.08);
  --CFD-theme-State-layers-secondary-fixed-opacity_12: rgba(222, 225, 249, 0.12);
  --CFD-theme-State-layers-secondary-fixed-opacity_16: rgba(222, 225, 249, 0.16);
  --CFD-theme-State-layers-secondary-opacity_08: rgba(194, 197, 221, 0.08);
  --CFD-theme-State-layers-secondary-opacity_12: rgba(194, 197, 221, 0.12);
  --CFD-theme-State-layers-secondary-opacity_16: rgba(194, 197, 221, 0.16);
  --CFD-theme-State-layers-surface-1-opacity_08: rgba(27, 32, 51, 0.08);
  --CFD-theme-State-layers-surface-1-opacity_12: rgba(27, 32, 51, 0.12);
  --CFD-theme-State-layers-surface-1-opacity_16: rgba(27, 32, 51, 0.16);
  --CFD-theme-State-layers-surface-2-opacity_08: rgba(29, 34, 54, 0.08);
  --CFD-theme-State-layers-surface-2-opacity_12: rgba(29, 34, 54, 0.12);
  --CFD-theme-State-layers-surface-2-opacity_16: rgba(29, 34, 54, 0.16);
  --CFD-theme-State-layers-surface-3-opacity_08: rgba(34, 40, 61, 0.08);
  --CFD-theme-State-layers-surface-3-opacity_12: rgba(34, 40, 61, 0.12);
  --CFD-theme-State-layers-surface-3-opacity_16: rgba(34, 40, 61, 0.16);
  --CFD-theme-State-layers-surface-4-opacity_08: rgba(38, 44, 66, 0.08);
  --CFD-theme-State-layers-surface-4-opacity_12: rgba(38, 44, 66, 0.12);
  --CFD-theme-State-layers-surface-4-opacity_16: rgba(38, 44, 66, 0.16);
  --CFD-theme-State-layers-surface-5-opacity_08: rgba(41, 47, 71, 0.08);
  --CFD-theme-State-layers-surface-5-opacity_12: rgba(41, 47, 71, 0.12);
  --CFD-theme-State-layers-surface-5-opacity_16: rgba(41, 47, 71, 0.16);
  --CFD-theme-State-layers-surface-bright-opacity_08: rgba(56, 57, 63, 0.08);
  --CFD-theme-State-layers-surface-bright-opacity_12: rgba(56, 57, 63, 0.12);
  --CFD-theme-State-layers-surface-bright-opacity_16: rgba(56, 57, 63, 0.16);
  --CFD-theme-State-layers-surface-dim-opacity_08: rgba(18, 19, 24, 0.08);
  --CFD-theme-State-layers-surface-dim-opacity_12: rgba(18, 19, 24, 0.12);
  --CFD-theme-State-layers-surface-dim-opacity_16: rgba(18, 19, 24, 0.16);
  --CFD-theme-State-layers-surface-opacity_08: rgba(20, 25, 45, 0.08);
  --CFD-theme-State-layers-surface-opacity_12: rgba(20, 25, 45, 0.12);
  --CFD-theme-State-layers-surface-opacity_16: rgba(20, 25, 45, 0.16);
  --CFD-theme-State-layers-surface-tint-opacity_08: rgba(183, 196, 255, 0.08);
  --CFD-theme-State-layers-surface-tint-opacity_12: rgba(183, 196, 255, 0.12);
  --CFD-theme-State-layers-surface-tint-opacity_16: rgba(183, 196, 255, 0.16);
  --CFD-theme-State-layers-surface-variant-opacity_08: rgba(62, 65, 79, 0.08);
  --CFD-theme-State-layers-surface-variant-opacity_12: rgba(62, 65, 79, 0.12);
  --CFD-theme-State-layers-surface-variant-opacity_16: rgba(62, 65, 79, 0.16);
  --CFD-theme-State-layers-tertiary-container-opacity_08: rgba(27, 81, 41, 0.08);
  --CFD-theme-State-layers-tertiary-container-opacity_12: rgba(27, 81, 41, 0.12);
  --CFD-theme-State-layers-tertiary-container-opacity_16: rgba(27, 81, 41, 0.16);
  --CFD-theme-State-layers-tertiary-fixed-dim-opacity_08: rgba(155, 212, 160, 0.08);
  --CFD-theme-State-layers-tertiary-fixed-dim-opacity_12: rgba(155, 212, 160, 0.12);
  --CFD-theme-State-layers-tertiary-fixed-dim-opacity_16: rgba(155, 212, 160, 0.16);
  --CFD-theme-State-layers-tertiary-fixed-opacity_08: rgba(182, 241, 187, 0.08);
  --CFD-theme-State-layers-tertiary-fixed-opacity_12: rgba(182, 241, 187, 0.12);
  --CFD-theme-State-layers-tertiary-fixed-opacity_16: rgba(182, 241, 187, 0.16);
  --CFD-theme-State-layers-tertiary-opacity_08: rgba(155, 212, 160, 0.08);
  --CFD-theme-State-layers-tertiary-opacity_12: rgba(155, 212, 160, 0.12);
  --CFD-theme-State-layers-tertiary-opacity_16: rgba(155, 212, 160, 0.16);
  --CFD-theme-Surface-1: #1B2033;
  --CFD-theme-Surface-2: #1D2236;
  --CFD-theme-Surface-3: #22283D;
  --CFD-theme-Surface-4: #262C42;
  --CFD-theme-Surface-5: #292F47;
  --CFD-theme-System-Background: #14192D;
  --CFD-theme-System-Error: #FFB4AB;
  --CFD-theme-System-ErrorContainer: #93000A;
  --CFD-theme-System-Inverse-primary: #4E5B92;
  --CFD-theme-System-Inverseonsurface: #2C2E36;
  --CFD-theme-System-Inversesurface: #E1E2E9;
  --CFD-theme-System-On-tertiary-fixed: #002109;
  --CFD-theme-System-OnBackground: #E3E1E9;
  --CFD-theme-System-OnError: #690005;
  --CFD-theme-System-OnErrorContainer: #FFDAD6;
  --CFD-theme-System-OnPrimary: #1E2D61;
  --CFD-theme-System-OnPrimaryContainer: #DCE1FF;
  --CFD-theme-System-OnSecondary: #2B3042;
  --CFD-theme-System-OnSecondaryContainer: #DEE1F9;
  --CFD-theme-System-OnSurface: #E3E1E9;
  --CFD-theme-System-OnSurfaceVariant: #BBBFD0;
  --CFD-theme-System-OnTertiary: #003915;
  --CFD-theme-System-OnTertiaryContainer: #B6F1BB;
  --CFD-theme-System-Onprimaryfixed: #05164B;
  --CFD-theme-System-Onprimaryfixedvariant: #CCCCCC;
  --CFD-theme-System-Onsecondaryfixed: #171B2C;
  --CFD-theme-System-Onsecondaryfixedvariant: #424659;
  --CFD-theme-System-Ontertiaryfixedvariant: #1B5129;
  --CFD-theme-System-Outline: #89899A;
  --CFD-theme-System-OutlineVariant: #41424F;
  --CFD-theme-System-Primary: #B7C4FF;
  --CFD-theme-System-PrimaryContainer: #364479;
  --CFD-theme-System-Primaryfixed: #DCE1FF;
  --CFD-theme-System-Primaryfixeddim: #B7C4FF;
  --CFD-theme-System-Scrim: #000000;
  --CFD-theme-System-Secondary: #C2C5DD;
  --CFD-theme-System-SecondaryContainer: #424659;
  --CFD-theme-System-Secondaryfixed: #DEE1F9;
  --CFD-theme-System-Secondaryfixeddim: #C2C5DD;
  --CFD-theme-System-Shadow: #000000;
  --CFD-theme-System-Surface: #14192D;
  --CFD-theme-System-SurfaceDim: #121318;
  --CFD-theme-System-SurfaceVariant: #3E414F;
  --CFD-theme-System-Surfacetint: #B7C4FF;
  --CFD-theme-System-Tertiary-fixed: #B6F1BB;
  --CFD-theme-System-Tertiary: #9BD4A0;
  --CFD-theme-System-TertiaryContainer: #1B5129;
  --CFD-theme-System-Tertiaryfixeddim: #9BD4A0;
  --CFD-theme-System-TableHead-Variant: #343b54;

  --button-text-color: #ffffff;
  --button-background-color: #4e005c;
  --button-border-color: #ba8fc2;

  --input-text-color: #ffffff;
  --input-placeholder-text-color: #dddddd;
  --input-background-color: #7c7c7c;
  --input-border-color: #ba8fc2;
}

html[data-theme='light'] {
  --common-background-color: #dfdfdf;
  --common-color: #000000;

  --CFD-theme-Custom-Green: #116A2C;
  --CFD-theme-Custom-GreenContainer: #94DBB0;
  --CFD-theme-Custom-OnGreen: #FFFFFF;
  --CFD-theme-Custom-OnGreenContainer: #00200F;
  --CFD-theme-Custom-OnRed: #FFFFFF;
  --CFD-theme-Custom-OnRedContainer: #3F0018;
  --CFD-theme-Custom-OnRurple: #FFFFFF;
  --CFD-theme-Custom-OnRurpleContainer: #00005C;
  --CFD-theme-Custom-Red: #DB2A3F;
  --CFD-theme-Custom-RedContainer: #FBB2BF;
  --CFD-theme-Custom-Purple: #4A4AA9;
  --CFD-theme-Custom-PurpleContainer: #BCBCEC;
  --CFD-theme-State-layers-background-opacity_08: rgba(250, 248, 255, 0.08);
  --CFD-theme-State-layers-background-opacity_12: rgba(250, 248, 255, 0.12);
  --CFD-theme-State-layers-background-opacity_16: rgba(250, 248, 255, 0.16);
  --CFD-theme-State-layers-error-container-opacity_08: rgba(255, 218, 214, 0.08);
  --CFD-theme-State-layers-error-container-opacity_12: rgba(255, 218, 214, 0.12);
  --CFD-theme-State-layers-error-container-opacity_16: rgba(255, 218, 214, 0.16);
  --CFD-theme-State-layers-error-opacity_08: rgba(186, 26, 26, 0.08);
  --CFD-theme-State-layers-error-opacity_12: rgba(186, 26, 26, 0.12);
  --CFD-theme-State-layers-error-opacity_16: rgba(186, 26, 26, 0.16);
  --CFD-theme-State-layers-green-container-opacity_08: rgba(148, 219, 176, 0.08);
  --CFD-theme-State-layers-green-container-opacity_12: rgba(148, 219, 176, 0.12);
  --CFD-theme-State-layers-green-container-opacity_16: rgba(148, 219, 176, 0.16);
  --CFD-theme-State-layers-green-opacity_08: rgba(17, 106, 44, 0.08);
  --CFD-theme-State-layers-green-opacity_12: rgba(17, 106, 44, 0.12);
  --CFD-theme-State-layers-green-opacity_16: rgba(17, 106, 44, 0.16);
  --CFD-theme-State-layers-green-opacity_38: rgba(17, 106, 44, 0.38);
  --CFD-theme-State-layers-inverse-on-surface-opacity_08: rgba(242, 240, 247, 0.08);
  --CFD-theme-State-layers-inverse-on-surface-opacity_12: rgba(242, 240, 247, 0.12);
  --CFD-theme-State-layers-inverse-on-surface-opacity_16: rgba(242, 240, 247, 0.16);
  --CFD-theme-State-layers-inverse-primary-opacity_08: rgba(183, 196, 255, 0.08);
  --CFD-theme-State-layers-inverse-primary-opacity_12: rgba(183, 196, 255, 0.12);
  --CFD-theme-State-layers-inverse-primary-opacity_16: rgba(183, 196, 255, 0.16);
  --CFD-theme-State-layers-inverse-surface-opacity_08: rgba(44, 46, 54, 0.08);
  --CFD-theme-State-layers-inverse-surface-opacity_12: rgba(44, 46, 54, 0.12);
  --CFD-theme-State-layers-inverse-surface-opacity_16: rgba(44, 46, 54, 0.16);
  --CFD-theme-State-layers-on-background-opacity_08: rgba(26, 27, 33, 0.08);
  --CFD-theme-State-layers-on-background-opacity_12: rgba(26, 27, 33, 0.12);
  --CFD-theme-State-layers-on-background-opacity_16: rgba(26, 27, 33, 0.16);
  --CFD-theme-State-layers-on-error-container-opacity_08: rgba(65, 0, 2, 0.08);
  --CFD-theme-State-layers-on-error-container-opacity_12: rgba(65, 0, 2, 0.12);
  --CFD-theme-State-layers-on-error-container-opacity_16: rgba(65, 0, 2, 0.16);
  --CFD-theme-State-layers-on-error-opacity_08: rgba(255, 255, 255, 0.08);
  --CFD-theme-State-layers-on-error-opacity_12: rgba(255, 255, 255, 0.12);
  --CFD-theme-State-layers-on-error-opacity_16: rgba(255, 255, 255, 0.16);
  --CFD-theme-State-layers-on-green-container-opacity_08: rgba(0, 32, 15, 0.08);
  --CFD-theme-State-layers-on-green-container-opacity_12: rgba(0, 32, 15, 0.12);
  --CFD-theme-State-layers-on-green-container-opacity_16: rgba(0, 32, 15, 0.16);
  --CFD-theme-State-layers-on-green-opacity_06: rgba(255, 255, 255, 0.16);
  --CFD-theme-State-layers-on-green-opacity_08: rgba(255, 255, 255, 0.08);
  --CFD-theme-State-layers-on-green-opacity_12: rgba(255, 255, 255, 0.12);
  --CFD-theme-State-layers-on-primary-container-opacity_08: rgba(5, 22, 75, 0.08);
  --CFD-theme-State-layers-on-primary-container-opacity_12: rgba(5, 22, 75, 0.12);
  --CFD-theme-State-layers-on-primary-container-opacity_16: rgba(5, 22, 75, 0.16);
  --CFD-theme-State-layers-on-primary-fixed-opacity_08: rgba(5, 22, 75, 0.08);
  --CFD-theme-State-layers-on-primary-fixed-opacity_12: rgba(5, 22, 75, 0.12);
  --CFD-theme-State-layers-on-primary-fixed-opacity_16: rgba(5, 22, 75, 0.16);
  --CFD-theme-State-layers-on-primary-fixed-variant-opacity_08: rgba(54, 68, 121, 0.08);
  --CFD-theme-State-layers-on-primary-fixed-variant-opacity_12: rgba(54, 68, 121, 0.12);
  --CFD-theme-State-layers-on-primary-fixed-variant-opacity_16: rgba(54, 68, 121, 0.16);
  --CFD-theme-State-layers-on-primary-opacity_08: rgba(255, 255, 255, 0.08);
  --CFD-theme-State-layers-on-primary-opacity_12: rgba(255, 255, 255, 0.12);
  --CFD-theme-State-layers-on-primary-opacity_16: rgba(255, 255, 255, 0.16);
  --CFD-theme-State-layers-on-purple-container-opacity_08: rgba(0, 0, 92, 0.08);
  --CFD-theme-State-layers-on-purple-container-opacity_12: rgba(0, 0, 92, 0.12);
  --CFD-theme-State-layers-on-purple-container-opacity_16: rgba(0, 0, 92, 0.16);
  --CFD-theme-State-layers-on-purple-opacity_08: rgba(255, 255, 255, 0.08);
  --CFD-theme-State-layers-on-purple-opacity_12: rgba(255, 255, 255, 0.12);
  --CFD-theme-State-layers-on-purple-opacity_16: rgba(255, 255, 255, 0.16);
  --CFD-theme-State-layers-on-red-container-opacity_08: rgba(63, 0, 24, 0.08);
  --CFD-theme-State-layers-on-red-container-opacity_12: rgba(63, 0, 24, 0.12);
  --CFD-theme-State-layers-on-red-container-opacity_16: rgba(63, 0, 24, 0.16);
  --CFD-theme-State-layers-on-red-opacity_08: rgba(255, 255, 255, 0.08);
  --CFD-theme-State-layers-on-red-opacity_12: rgba(255, 255, 255, 0.12);
  --CFD-theme-State-layers-on-red-opacity_16: rgba(255, 255, 255, 0.16);
  --CFD-theme-State-layers-on-secondary-container-opacity_08: rgba(23, 27, 44, 0.08);
  --CFD-theme-State-layers-on-secondary-container-opacity_12: rgba(23, 27, 44, 0.12);
  --CFD-theme-State-layers-on-secondary-container-opacity_16: rgba(23, 27, 44, 0.16);
  --CFD-theme-State-layers-on-secondary-fixed-opacity_08: rgba(23, 27, 44, 0.08);
  --CFD-theme-State-layers-on-secondary-fixed-opacity_12: rgba(23, 27, 44, 0.12);
  --CFD-theme-State-layers-on-secondary-fixed-opacity_16: rgba(23, 27, 44, 0.16);
  --CFD-theme-State-layers-on-secondary-opacity_08: rgba(255, 255, 255, 0.08);
  --CFD-theme-State-layers-on-secondary-opacity_12: rgba(255, 255, 255, 0.12);
  --CFD-theme-State-layers-on-secondary-opacity_16: rgba(255, 255, 255, 0.16);
  --CFD-theme-State-layers-on-surface-opacity_08: rgba(24, 26, 33, 0.08);
  --CFD-theme-State-layers-on-surface-opacity_12: rgba(24, 26, 33, 0.12);
  --CFD-theme-State-layers-on-surface-opacity_16: rgba(24, 26, 33, 0.16);
  --CFD-theme-State-layers-on-surface-variant-opacity_08: rgba(61, 64, 79, 0.08);
  --CFD-theme-State-layers-on-surface-variant-opacity_12: rgba(61, 64, 79, 0.12);
  --CFD-theme-State-layers-on-surface-variant-opacity_16: rgba(61, 64, 79, 0.16);
  --CFD-theme-State-layers-on-tertiary-container-opacity_08: rgba(0, 33, 9, 0.08);
  --CFD-theme-State-layers-on-tertiary-container-opacity_12: rgba(0, 33, 9, 0.12);
  --CFD-theme-State-layers-on-tertiary-container-opacity_16: rgba(0, 33, 9, 0.16);
  --CFD-theme-State-layers-on-tertiary-fixed-opacity_08: rgba(0, 33, 9, 0.08);
  --CFD-theme-State-layers-on-tertiary-fixed-opacity_12: rgba(0, 33, 9, 0.12);
  --CFD-theme-State-layers-on-tertiary-fixed-opacity_16: rgba(0, 33, 9, 0.16);
  --CFD-theme-State-layers-on-tertiary-fixed-variant-opacity_08: rgba(27, 81, 41, 0.08);
  --CFD-theme-State-layers-on-tertiary-fixed-variant-opacity_12: rgba(27, 81, 41, 0.12);
  --CFD-theme-State-layers-on-tertiary-fixed-variant-opacity_16: rgba(27, 81, 41, 0.16);
  --CFD-theme-State-layers-on-tertiary-opacity_08: rgba(255, 255, 255, 0.08);
  --CFD-theme-State-layers-on-tertiary-opacity_12: rgba(255, 255, 255, 0.12);
  --CFD-theme-State-layers-on-tertiary-opacity_16: rgba(255, 255, 255, 0.16);
  --CFD-theme-State-layers-onsecondaryfixedvariant-opacity_08: rgba(66, 70, 89, 0.08);
  --CFD-theme-State-layers-onsecondaryfixedvariant-opacity_12: rgba(66, 70, 89, 0.12);
  --CFD-theme-State-layers-onsecondaryfixedvariant-opacity_16: rgba(66, 70, 89, 0.16);
  --CFD-theme-State-layers-outline-opacity_08: rgba(118, 118, 128, 0.08);
  --CFD-theme-State-layers-outline-opacity_12: rgba(118, 118, 128, 0.12);
  --CFD-theme-State-layers-outline-opacity_16: rgba(118, 118, 128, 0.16);
  --CFD-theme-State-layers-outline-variant-opacity_08: rgba(189, 187, 208, 0.08);
  --CFD-theme-State-layers-outline-variant-opacity_12: rgba(189, 187, 208, 0.12);
  --CFD-theme-State-layers-outline-variant-opacity_16: rgba(189, 187, 208, 0.16);
  --CFD-theme-State-layers-primary-container-opacity_08: rgba(220, 225, 255, 0.08);
  --CFD-theme-State-layers-primary-container-opacity_12: rgba(220, 225, 255, 0.12);
  --CFD-theme-State-layers-primary-container-opacity_16: rgba(220, 225, 255, 0.16);
  --CFD-theme-State-layers-primary-fixed-dim-opacity_08: rgba(183, 196, 255, 0.08);
  --CFD-theme-State-layers-primary-fixed-dim-opacity_12: rgba(183, 196, 255, 0.12);
  --CFD-theme-State-layers-primary-fixed-dim-opacity_16: rgba(183, 196, 255, 0.16);
  --CFD-theme-State-layers-primary-fixed-opacity_08: rgba(220, 225, 255, 0.08);
  --CFD-theme-State-layers-primary-fixed-opacity_12: rgba(220, 225, 255, 0.12);
  --CFD-theme-State-layers-primary-fixed-opacity_16: rgba(220, 225, 255, 0.16);
  --CFD-theme-State-layers-primary-opacity_08: rgba(78, 91, 146, 0.08);
  --CFD-theme-State-layers-primary-opacity_12: rgba(78, 91, 146, 0.12);
  --CFD-theme-State-layers-primary-opacity_16: rgba(78, 91, 146, 0.16);
  --CFD-theme-State-layers-purple-container-opacity_08: rgba(188, 188, 236, 0.08);
  --CFD-theme-State-layers-purple-container-opacity_12: rgba(188, 188, 236, 0.12);
  --CFD-theme-State-layers-purple-container-opacity_16: rgba(188, 188, 236, 0.16);
  --CFD-theme-State-layers-purple-opacity_08: rgba(74, 74, 169, 0.08);
  --CFD-theme-State-layers-purple-opacity_12: rgba(74, 74, 169, 0.12);
  --CFD-theme-State-layers-purple-opacity_16: rgba(74, 74, 169, 0.16);
  --CFD-theme-State-layers-red-container-opacity_08: rgba(251, 178, 191, 0.08);
  --CFD-theme-State-layers-red-container-opacity_12: rgba(251, 178, 191, 0.12);
  --CFD-theme-State-layers-red-container-opacity_16: rgba(251, 178, 191, 0.16);
  --CFD-theme-State-layers-red-opacity_08: rgba(219, 42, 63, 0.08);
  --CFD-theme-State-layers-red-opacity_12: rgba(219, 42, 63, 0.12);
  --CFD-theme-State-layers-red-opacity_16: rgba(219, 42, 63, 0.16);
  --CFD-theme-State-layers-red-opacity_38: rgba(219, 42, 63, 0.38);
  --CFD-theme-State-layers-scrim-opacity_08: rgba(0, 0, 0, 0.08);
  --CFD-theme-State-layers-scrim-opacity_12: rgba(0, 0, 0, 0.12);
  --CFD-theme-State-layers-scrim-opacity_16: rgba(0, 0, 0, 0.16);
  --CFD-theme-State-layers-secondary-container-opacity_08: rgba(222, 225, 249, 0.08);
  --CFD-theme-State-layers-secondary-container-opacity_12: rgba(222, 225, 249, 0.12);
  --CFD-theme-State-layers-secondary-container-opacity_16: rgba(222, 225, 249, 0.16);
  --CFD-theme-State-layers-secondary-fixed-dim-opacity_08: rgba(194, 197, 221, 0.08);
  --CFD-theme-State-layers-secondary-fixed-dim-opacity_12: rgba(194, 197, 221, 0.12);
  --CFD-theme-State-layers-secondary-fixed-dim-opacity_16: rgba(194, 197, 221, 0.16);
  --CFD-theme-State-layers-secondary-fixed-opacity_08: rgba(222, 225, 249, 0.08);
  --CFD-theme-State-layers-secondary-fixed-opacity_12: rgba(222, 225, 249, 0.12);
  --CFD-theme-State-layers-secondary-fixed-opacity_16: rgba(222, 225, 249, 0.16);
  --CFD-theme-State-layers-secondary-opacity_08: rgba(90, 93, 114, 0.08);
  --CFD-theme-State-layers-secondary-opacity_12: rgba(90, 93, 114, 0.12);
  --CFD-theme-State-layers-secondary-opacity_16: rgba(90, 93, 114, 0.16);
  --CFD-theme-State-layers-surface-1-opacity_08: rgba(233, 235, 245, 0.08);
  --CFD-theme-State-layers-surface-1-opacity_12: rgba(233, 235, 245, 0.12);
  --CFD-theme-State-layers-surface-1-opacity_16: rgba(233, 235, 245, 0.16);
  --CFD-theme-State-layers-surface-2-opacity_08: rgba(230, 233, 245, 0.08);
  --CFD-theme-State-layers-surface-2-opacity_12: rgba(230, 233, 245, 0.12);
  --CFD-theme-State-layers-surface-2-opacity_16: rgba(230, 233, 245, 0.16);
  --CFD-theme-State-layers-surface-3-opacity_08: rgba(221, 223, 237, 0.08);
  --CFD-theme-State-layers-surface-3-opacity_12: rgba(221, 223, 237, 0.12);
  --CFD-theme-State-layers-surface-3-opacity_16: rgba(221, 223, 237, 0.16);
  --CFD-theme-State-layers-surface-4-opacity_08: rgba(218, 221, 237, 0.08);
  --CFD-theme-State-layers-surface-4-opacity_12: rgba(218, 221, 237, 0.12);
  --CFD-theme-State-layers-surface-4-opacity_16: rgba(218, 221, 237, 0.16);
  --CFD-theme-State-layers-surface-5-opacity_08: rgba(213, 217, 235, 0.08);
  --CFD-theme-State-layers-surface-5-opacity_12: rgba(213, 217, 235, 0.12);
  --CFD-theme-State-layers-surface-5-opacity_16: rgba(213, 217, 235, 0.16);
  --CFD-theme-State-layers-surface-bright-opacity_08: rgba(250, 248, 255, 0.08);
  --CFD-theme-State-layers-surface-bright-opacity_12: rgba(250, 248, 255, 0.12);
  --CFD-theme-State-layers-surface-bright-opacity_16: rgba(250, 248, 255, 0.16);
  --CFD-theme-State-layers-surface-dim-opacity_08: rgba(219, 217, 224, 0.08);
  --CFD-theme-State-layers-surface-dim-opacity_12: rgba(219, 217, 224, 0.12);
  --CFD-theme-State-layers-surface-dim-opacity_16: rgba(219, 217, 224, 0.16);
  --CFD-theme-State-layers-surface-opacity_08: rgba(242, 242, 242, 0.08);
  --CFD-theme-State-layers-surface-opacity_12: rgba(242, 242, 242, 0.12);
  --CFD-theme-State-layers-surface-opacity_16: rgba(242, 242, 242, 0.16);
  --CFD-theme-State-layers-surface-tint-opacity_08: rgba(78, 91, 146, 0.08);
  --CFD-theme-State-layers-surface-tint-opacity_12: rgba(78, 91, 146, 0.12);
  --CFD-theme-State-layers-surface-tint-opacity_16: rgba(78, 91, 146, 0.16);
  --CFD-theme-State-layers-surface-variant-opacity_08: rgba(202, 207, 228, 0.08);
  --CFD-theme-State-layers-surface-variant-opacity_12: rgba(202, 207, 228, 0.12);
  --CFD-theme-State-layers-surface-variant-opacity_16: rgba(202, 207, 228, 0.16);
  --CFD-theme-State-layers-tertiary-container-opacity_08: rgba(182, 241, 187, 0.08);
  --CFD-theme-State-layers-tertiary-container-opacity_12: rgba(182, 241, 187, 0.12);
  --CFD-theme-State-layers-tertiary-container-opacity_16: rgba(182, 241, 187, 0.16);
  --CFD-theme-State-layers-tertiary-fixed-dim-opacity_08: rgba(155, 212, 160, 0.08);
  --CFD-theme-State-layers-tertiary-fixed-dim-opacity_12: rgba(155, 212, 160, 0.12);
  --CFD-theme-State-layers-tertiary-fixed-dim-opacity_16: rgba(155, 212, 160, 0.16);
  --CFD-theme-State-layers-tertiary-fixed-opacity_08: rgba(182, 241, 187, 0.08);
  --CFD-theme-State-layers-tertiary-fixed-opacity_12: rgba(182, 241, 187, 0.12);
  --CFD-theme-State-layers-tertiary-fixed-opacity_16: rgba(182, 241, 187, 0.16);
  --CFD-theme-State-layers-tertiary-opacity_08: rgba(52, 105, 63, 0.08);
  --CFD-theme-State-layers-tertiary-opacity_12: rgba(52, 105, 63, 0.12);
  --CFD-theme-State-layers-tertiary-opacity_16: rgba(52, 105, 63, 0.16);
  --CFD-theme-Surface-1: #E9EBF5;
  --CFD-theme-Surface-2: #E6E9F5;
  --CFD-theme-Surface-3: #DDDFED;
  --CFD-theme-Surface-4: #DADDED;
  --CFD-theme-Surface-5: #D5D9EB;
  --CFD-theme-System-Background: #DFE0FF;
  --CFD-theme-System-Error: #BA1A1A;
  --CFD-theme-System-ErrorContainer: #FFDAD6;
  --CFD-theme-System-Inverse-primary: #B7C4FF;
  --CFD-theme-System-Inverseonsurface: #F2F0F7;
  --CFD-theme-System-Inversesurface: #2C2E36;
  --CFD-theme-System-On-tertiary-fixed: #002109;
  --CFD-theme-System-OnBackground: #1A1B21;
  --CFD-theme-System-OnError: #FFFFFF;
  --CFD-theme-System-OnErrorContainer: #410002;
  --CFD-theme-System-OnPrimary: #FFFFFF;
  --CFD-theme-System-OnPrimaryContainer: #05164B;
  --CFD-theme-System-OnSecondary: #FFFFFF;
  --CFD-theme-System-OnSecondaryContainer: #171B2C;
  --CFD-theme-System-OnSurface: #181A21;
  --CFD-theme-System-OnSurfaceVariant: #3D404F;
  --CFD-theme-System-OnTertiary: #FFFFFF;
  --CFD-theme-System-OnTertiaryContainer: #002109;
  --CFD-theme-System-Onprimaryfixed: #05164B;
  --CFD-theme-System-Onprimaryfixedvariant: #364479;
  --CFD-theme-System-Onsecondaryfixed: #171B2C;
  --CFD-theme-System-Onsecondaryfixedvariant: #424659;
  --CFD-theme-System-Ontertiaryfixedvariant: #1B5129;
  --CFD-theme-System-Outline: #6F6F80;
  --CFD-theme-System-OutlineVariant: #BDBBD0;
  --CFD-theme-System-Primary: #4E5B92;
  --CFD-theme-System-PrimaryContainer: #DCE1FF;
  --CFD-theme-System-Primaryfixed: #DCE1FF;
  --CFD-theme-System-Primaryfixeddim: #B7C4FF;
  --CFD-theme-System-Scrim: #000000;
  --CFD-theme-System-Secondary: #5A5D72;
  --CFD-theme-System-SecondaryContainer: #DEE1F9;
  --CFD-theme-System-Secondaryfixed: #DEE1F9;
  --CFD-theme-System-Secondaryfixeddim: #C2C5DD;
  --CFD-theme-System-Shadow: #000000;
  --CFD-theme-System-Surface: #F2F2F2;
  --CFD-theme-System-SurfaceDim: #DBD9E0;
  --CFD-theme-System-SurfaceVariant: #CACFE4;
  --CFD-theme-System-Surfacetint: #4E5B92;
  --CFD-theme-System-Tertiary-fixed: #B6F1BB;
  --CFD-theme-System-Tertiary: #34693F;
  --CFD-theme-System-TertiaryContainer: #B6F1BB;
  --CFD-theme-System-Tertiaryfixeddim: #9BD4A0;
  --CFD-theme-System-TableHead-Variant: #cccfe2;

  --button-text-color: #252525;
  --button-background-color: #f9d4ff;
  --button-border-color: #4e005c;

  --input-text-color: #252525;
  --input-placeholder-text-color: #b6b6b6;
  --input-background-color: #ffffff;
  --input-border-color: #4e005c;
}

@font-face {
  font-family: 'Arial Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Arial Regular'), url('./fonts/ARIAL.woff') format('woff');
}

@font-face {
  font-family: 'Cairo-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Cairo-Regular'), url('./fonts/Cairo-Regular.ttf') format('ttf');
}