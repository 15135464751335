.historyTableHeadWrapper {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  background-color: var(--CFD-theme-Surface-2);
  padding: 24px;
}

.historyTableHeadTitle {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.historyTableHeadText {
  color: var(--CFD-theme-System-OnSurfaceVariant);
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  letter-spacing: 0.15px;
}

.historyTableFilterWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  gap: 16px;
  width: 100%;
}

.historyTableFilters {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}

.historyFilterCell {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 155px;
}

.historyBtnWrapper {
  display: flex;
  flex-direction: row;
}

.historyBtnWrapperHeader {
  display: none;
}

@media screen and (max-width: 905px) {
  .historyBtnWrapper {
    display: none;
  }

  .historyBtnWrapperHeader {
    display: flex;
    flex-direction: row;
  }

  .historyTableHeadTitle {
    justify-content: space-between;
  }
}  