.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 16px;
  gap: 24px;
  overflow: auto;
}

.cardWrapper {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  gap: 16px;
}

.cardWrapperText {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-Outline);
}

.cardForm {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  gap: 24px;
}

.selectWrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.labelCoin {
  margin-left: 16px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; 
  letter-spacing: 0.4px;
}

.creditCardWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
}

.fullNameWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  gap: 6px;
}

.expireWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  gap: 6px;
  padding-bottom: 12px;
}

.fullName {
  display: flex;
  flex-direction: column;
  gap: 6px;
  max-width: 360px;
  width: 100%;
}

.footer {
  position: fixed;
  bottom: 0;
  box-sizing: border-box;
  width: 530px;
  display: flex;
  padding: 24px;
  background-color: var(--CFD-theme-Surface-1);
  border-top: 1px solid var(--CFD-theme-System-OutlineVariant);
}

.btnContainer {
  width: 100%;
}

.btnText {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  text-align: center;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.1px;
}

.radioCardsWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media screen and (max-width: 599px) {
  .wrapper {
    height: 100vh;
    padding-bottom: 60px;
  }
  .fullName {
    max-width: 500px;
  }
  .footer {
    width: 100%;
  }
}

