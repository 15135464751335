.btnIcon {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  max-height: 24px;
}

.svgSize {
  width: 24px;
  height: 24px;
}