.dialogHeader {
    display: flex;
    height: 72px;
    width: 100%;
    align-items: center;
    border-top-left-radius: 11px;
    border-top-right-radius: 11px;
    background-color: var(--CFD-theme-State-layers-on-surface-opacity_08);
}

.dialogHeaderContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    height: 100%;
}

.dialogHeaderContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.dialogHeaderTitle {
    margin-bottom: 0;
    margin-left: 24px;
    color: var(--CFD-theme-System-OnSurface);
    font-family: Arial;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; 
}

.dialogHeaderText {
    margin-bottom: 0;
    margin-top: 5px;
    margin-left: 24px;
    color: var(--CFD-theme-System-OnSurface);
    font-family: Arial;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.5px;
}

.dialogHeaderBtnContainer {
    margin-right: 16px;
}

@media screen and (max-width: 600px) {
    .notFullScreen {
        max-width: 400px;
    }
}

@media screen and (max-width: 440px) {
    .notFullScreen {
        max-width: 330px;
    }
}

@media screen and (max-width: 370px) {
    .notFullScreen {
        max-width: 310px;
    }
}
