.headerLiveChat {
  width: 100%;
  box-sizing: border-box;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 4px;
  background-color: var(--CFD-theme-Surface-4);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.headerBtnContainer {
  width: 48px;
  height: 48px;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

.headerBtnContainer:hover {
  background-color: var(--CFD-theme-State-layers-on-surface-variant-opacity_12);
  border-radius: 50%;
}
.headerLiveChatTitle {
  font-family: Arial;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: var(--CFD-theme-System-OnSurface);
}

.headerOptions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dotStatisLiveChat {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 28px;
}

.headerLiveChatTitleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.unreadMessages {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 24px;
  max-height: 24px;
  min-width: 16px;
  min-height: 16px;
  background-color: var(--CFD-theme-System-Error);
  border-radius: 50%;
}

.unreadMessagesText {
  margin-top: 0;
  margin-bottom: 0;
  color: var(--CFD-theme-System-OnError);;
  text-align: center;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; 
  letter-spacing: 0.3px;
}

.headerLiveMobileChat {
  display: none;
}

.headerLiveMobileLogo {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
}

.headerLiveMobileBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.svg {
  color: var(--CFD-theme-System-Primary);
  width: 20px;
  height: 20px;
}

.headerLiveMobileOptions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.headerLiveMobileUserIcon {
  height: 40px;
  min-height: 40px;
  width: 40px;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;
  text-transform: capitalize;
  background-color: var(--CFD-theme-System-Primary);
  border-radius: 50%;
}

.wrapper-dark {
  background: linear-gradient(0deg, var(--CFD-Theme-State-layers-surface-variant-opacity_08, rgba(62, 65, 79, 0.08)) 0%, var(--CFD-Theme-State-layers-surface-variant-opacity_08, rgba(62, 65, 79, 0.08)) 100%), var(--CFD-Theme-System-Surface, #14192D);
}

.wrapper-light {
  background: linear-gradient(0deg, var(--State-layers-surface-variant-opacity_08, rgba(202, 207, 228, 0.08)) 0%, var(--State-layers-surface-variant-opacity_08, rgba(202, 207, 228, 0.08)) 100%), var(--System-Surface, #F2F2F2);
}

.headerMenuBtn {
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
}

.headerTitle {
  color: var(--CFD-theme-System-Primary);
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  letter-spacing: 0.15px;
}

.infoWrapperSelectBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  outline: none;
  border: none;
  background-color: var(--CFD-theme-Surface-3);
  padding: 4px 8px;
  border-radius: 100px;
  cursor: pointer;
  transition: background-color 0.3s ease, height 0.3s ease;
}

.infoWrapperSelectBtnContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.infoWrapperSelectBalance {
  display: flex;
  color: var(--CFD-theme-System-Secondary);
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  padding-left: 14px;
  width: 100%;
}

.infoWrapperSelectTitle {
  color: var(--CFD-theme-System-Secondary);
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  white-space: nowrap;
  padding-left: 8px;
}

.infoWrapperSelectIconMobile {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media screen and (max-width: 599px) {
  .headerLiveChat {
    display: none;
  }
  .headerLiveMobileChat {
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    box-sizing: border-box;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 12px;
  }
}

@media screen and (max-width: 450px) {
  .headerTitle {
    display: none;
  }
}