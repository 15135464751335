.userInfo {
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
}

.userAdditionalInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
}

.userName {
  width: auto;
  padding-left: 12px;
  text-align: left;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.userName span {
  width: 100%;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-OnSurface);
}

.userEmail {
  width: auto;
  padding-left: 12px;
  text-align: left;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.userEmail span {
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  width: 100%;
}