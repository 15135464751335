.sectionWrapper {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 20px;
}

.detailsWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.detailsText {
  margin-top: 4px;
  margin-bottom: 4px;
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-OnSurface);
}

.detailsText1 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}

.detailsCreated {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.detailsInfo {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 16px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: var(--CFD-theme-System-Inversesurface);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--CFD-theme-System-Inverseonsurface);
}

.summaryContainer {
  display: flex;
  flex-direction: column;
}

.summaryAmountContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.summaryAmountTitle {
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}

.summaryAmount {
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--CFD-theme-System-Tertiary);
}

.emptySummaryContainer {
  display: none;
  height: 75px;
}

@media screen and (max-width: 904px) {
  .sectionWrapper {
    overflow: scroll;
    max-height: calc(100dvh - 232px);
  }

  .emptySummaryContainer {
    display: block;
  }

  .detailsInfo {
    font-size: 12px;
    line-height: 14px;
  }

  .detailsCreated {
    margin-top: 5px;
  }
}

@media screen and (max-width: 400px) {
  .sectionWrapper {
    max-height: calc(100dvh - 200px);
  }
}
