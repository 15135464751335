.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}
.noDataWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 180px;
  width: 100%;
  font-size: 18px;
}
.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
  font-size: 18px;
}

.emptyContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.emptyContainer span {
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.emptyContainer span:first-of-type {
  color: var(--CFD-theme-System-OnSurface);
  margin-top: 16px;
  margin-bottom: 4px;
}

.emptyContainer span:last-of-type {
  color: var(--CFD-theme-System-OnSurfaceVariant);
}

.percentageRate {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  bottom: 84px;
  color: var(--CFD-theme-System-Outline);
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
}
