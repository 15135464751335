.buySellContainer {
    border-radius: 6px;
    border: 1px solid var(--CFD-theme-State-layers-outline-opacity_16);
    background-color: var(--CFD-theme-Surface-1);
    min-width: 300px;
    min-width: 300px;
    overflow: hidden;
    height: 100%;
    position: relative;
}

.buySellContainerHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    font-family: Arial;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    color: var(--CFD-theme-System-OnSurface);
}

.buySellContent {
    padding: 16px;
    max-height: calc(100% - 64px - 44px - 66px);
    overflow-y: auto;
}

.titleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    width: 100%;
    justify-content: space-between;
}

.buySellContent__inputRow:first-of-type {
    margin-bottom: 16px;
}

.buySellContent__inputRow label {
    color: var(--CFD-theme-System-OnSurfaceVariant);
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.4px;
    margin-left: 15px;
    margin-bottom: 5px;
    display: inline-block;
}

.buySellContent__chipsRow {
    margin-top: 24px;
    margin-bottom: 16px;
    display: flex;
}

.buySellContent__chipsRow>div {
    margin-right: 12px;
}

.buySellContent__chipsRow>div:last-of-type {
    margin-right: 0;
}

.buySellContent__infoTable {
    margin: 24px 0;
    border-radius: 8px;
    background: var(--CFD-theme-State-layers-surface-variant-opacity_16);
}

.buySellContent__infoTable .buySellContent__infoTableRow {
    padding: 5px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 24px;
    border-bottom: 1px solid var(--CFD-theme-State-layers-outline-opacity_16);
}

.buySellContent__infoTable .buySellContent__infoTableRow:last-of-type {
    border-bottom: none;
}

.buySellContent__infoTable .buySellContent__infoTableRow span:first-of-type {
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: var(--CFD-theme-System-OnSurfaceVariant)
}

.buySellContent__infoTable .buySellContent__infoTableRow span:last-of-type {
    color: var(--CFD-theme-System-OnSurface);
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
}

.buySellContent__configurationTable {
    margin: 16px 0;
}

.buySellContent__configurationTable .buySellContent__configurationTableColumn {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--CFD-theme-System-OutlineVariant);
}

.buySellContent__configurationTable .buySellContent__configurationTableColumn:last-of-type {
    border-bottom: unset;
}

.buySellContent__configurationTable .buySellContent__configurationTableColumn .buySellContent__configurationTableRow {
    display: flex;
    align-items: center;
    height: 48px;
    padding: 8px 0;
}

.buySellContent__configurationTable .buySellContent__configurationTableColumn .buySellContent__configurationTableRow label {
    margin-left: 4px;
    color: var(--CFD-theme-System-OnSurfaceVariant);
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    cursor: pointer;
}

.buySellContent__configurationTable .buySellContent__configurationTableColumn .buySellContent__configurationTableColumn__subContent {
    margin-top: 4px;
    padding-bottom: 16px;
}

.buySellContent__configurationTable .buySellContent__configurationTableColumn .buySellContent__configurationTableColumn__subContent .buySellContent__configurationTableColumn__subContentRow:nth-child(2) {
    margin-top: 25px;
}

.revertBtn {
    margin: 16px 0;
    min-width: 192px;
    background: var(--CFD-theme-System-Surface);
    border-radius: 8px;
    border: 1px solid var(--CFD-theme-State-layers-on-surface-opacity_12);
    cursor: pointer;
    padding: 6px 8px;
    display: flex;
    align-items: center;
}

.revertBtn span {
    color: var(--CFD-theme-System-OnSurfaceVariant);
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
    margin-left: 8px;
}

.revertBtn:hover {
    background-color: var(--CFD-theme-State-layers-on-surface-variant-opacity_08)
}

.supportText {
    color: var(--CFD-theme-System-OnSurfaceVariant);
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    max-width: 300px;
}

.buySellContainer__mainBtnContainer {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 16px;
    background-color: var(--CFD-theme-Surface-2);
    border-top: 1px solid var(--CFD-theme-System-OutlineVariant);
    display: flex;
    align-items: center;
}

.buySellContainer__mainBtnContainer>button {
    width: calc(50% - 4px);
}

.buySellContainer__mainBtnContainer>button:first-of-type {
    margin-right: 4px;
}

.buySellContainer__mainBtnContainer>button:last-of-type {
    margin-left: 4px;
}

.buttonAutoTrader {
    width: 100px;
}

.dialogHeader {
    padding: 24px;
    color: var(--CFD-theme-System-OnSurface);
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
}

.dialogContent {
    background-color: var(--CFD-theme-State-layers-surface-variant-opacity_16);
    list-style: unset;
    width: 312px
}

.dialogContent>li {
    padding: 8px 24px;
    border-bottom: 1px solid var(--CFD-theme-State-layers-outline-opacity_16);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dialogContent li:last-of-type {
    border: unset;
}

.dialogContent .label {
    color: var(--CFD-theme-System-OnSurfaceVariant);
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
}

.dialogContent .value {
    color: var(--CFD-theme-System-OnSurface);
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
}

.dialogFooter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 24px;
    gap: 8px
}

.buySellContainerHeaderContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.buySellContainerBackBtn {
    display: none;
}

.buySellContainerHeaderAsset {
    display: none;
}

.buySellContent_profitCalculator {
    margin: 16px 0px;
    width: 50%;
}

@media screen and (max-width: 1280px) {
    .buySellContainer {
        width: 100%;
    }

    .buySellContainerHeaderAsset {
        display: flex;
        flex-direction: row;
        font-family: Arial;
        font-weight: 600;
    }

    .buySellContainerBackBtn {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        outline: none;
        border: none;
        background-color: transparent;
    }

    .buttonAutoTrader {
        width: 119px;
    }
}