.accardionCellSummary {
  height: 100%;
  width: 100%;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.accardionTopCellSummary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.accardionCellRateWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.accardionCellCunmulWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-right: 1px solid var(--CFD-theme-System-SecondaryContainer);
  border-left: 1px solid var(--CFD-theme-System-SecondaryContainer);
}

.accardionPeriodWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.accardionPeriod {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.accardionCumulWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 8px;
}

.accardionTopBottomSummary {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.accardionCellRelese {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.accardionCellReleseTimer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.accardionCellWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 8px;
}

.accardionCellTextWrapper {
  color: var(--CFD-theme-System-Outline);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
}

.accardionCellText {
  color: var(--CFD-theme-System-OnSurface);
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
  margin-bottom: 0;
}


.accardionCellStatusWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  gap: 8px;
  padding-right: 8px;
}

.statusUnlocked {
  margin-left: 5%;
  border-radius: 8px;
  width: 77px;
  height: 24px;
  background-color: color-mix(in srgb, var(--CFD-theme-Custom-Green), transparent 88%);
  color: var(--CFD-theme-Custom-Green);
}

.statusLocked {
  margin-left: 5%;
  border-radius: 8px;
  width: 77px;
  height: 24px;
  background-color: color-mix(in srgb, var(--CFD-theme-Custom-Red), transparent 88%);
  color: var(--CFD-theme-Custom-Red);
}

.statusActive {
  border-radius: 8px;
  width: 77px;
  height: 24px;
  background-color: color-mix(in srgb, var(--CFD-theme-Custom-Purple), transparent 88%);
  color: var(--CFD-theme-Custom-Purple);
}

.accardionStatusCellText {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: 0.4px;
}


.accardionCellTimeLeftHoursWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

.accardionCellTimeLeftHoursCell {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.accardionCellTimeLeftWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

.accardionCellTimeLeftTitle {
  color: var(--CFD-theme-System-Outline);
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.5px;
}


.accardionCellTimeLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 2px;
  border: 1px solid var(--CFD-theme-System-Outline);
  width: 36px;
  height: 28px;
}

.accardionCellTimeLeftText {
  color: var(--CFD-theme-System-OnSurface);
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.1px;
}

.accardionCellTimeLeftDots {
  color: var(--CFD-theme-System-OnSurface);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.1px;
}

@media  screen and (max-width: 700px ) {
  .accardionCellCunmulWrapper {
    min-width: 150px;
  }
  .accardionCellReleseTimer {
    padding-left: 24px;
  }
  .accardionPeriodWrapper {
    padding-left: 8px;
  }
}

@media  screen and (max-width: 500px ) {
  .accardionCellCunmulWrapper {
    min-width: 110px;
    padding-left: 4px;
    padding-right: 4px;
  }

  .accardionCellReleseTimer {
    padding-left: 12px;
  }

  .accardionPeriodWrapper {
    padding-left: 8px;
  }

  .accardionCellWrapper {
    padding-right: 4px;
    gap: 4px;
  }

  .accardionCellTextWrapper {
    font-size: 10px;
    line-height: 8px;
  }
}

@media  screen and (max-width: 440px ) {
  .accardionCellCunmulWrapper {
    min-width: 90px;
    padding-left: 4px;
    padding-right: 4px;
  }

  .accardionCellReleseTimer {
    padding-left: 4px;
  }

  .accardionPeriodWrapper {
    padding-left: 4px;
  }
}

@media  screen and (max-width: 400px ) {
  .accardionCellTimeLeft {
    width: 28px;
  }
  .accardionCellReleseTimer {
    gap: 2px;
  }
  .accardionCellTimeLeftHoursCell {
    gap: 2px;
  }
}


