.cellTextContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.cellTextBtnIcon {
  outline: none;
  border: none;
  background-color: transparent;
  text-align: center;
  margin-top: 5px;
  padding: 0;
}