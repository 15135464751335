.dialogHeader {
    padding: 24px;
    color: var(--CFD-theme-System-OnSurface);
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
}

.dialogContent {
    background-color: var(--CFD-theme-State-layers-surface-variant-opacity_16);
    list-style: unset;
    width: 312px
}

.dialogContent > li {
    padding: 8px 24px;
    border-bottom: 1px solid var(--CFD-theme-State-layers-outline-opacity_16);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dialogContent li:last-of-type {
    border: unset;
}

.dialogContent .label {
    color: var(--CFD-theme-System-OnSurfaceVariant);
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
}

.dialogContent .value {
    color: var(--CFD-theme-System-OnSurface);
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
}

.dialogFooter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 24px;
    gap: 8px
}

.editDialogContent {
    background-color: var(--CFD-theme-State-layers-surface-variant-opacity_16);
    width: 400px;
    margin-bottom: 25px;
}

.editDialogContent  > li {
    padding: 8px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.editDialogContent .label {
    color: var(--CFD-theme-System-OnSurfaceVariant);
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
}

.editDialogContent .value {
    color: var(--CFD-theme-System-OnSurface);
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
}

.inputsContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
}

.editDialogFooter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 24px;
    gap: 8px
}

.editDialogFooter > button {
    width: 100px;
}

.stopDialogContent {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.iconRow {
    display: flex;
    justify-content: center;
}

.textColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.textColumn h2 {
    color: var(--CFD-theme-System-OnSurface);
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px
}

.textColumn span {
    color: var(--CFD-theme-System-OnSurface);
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
}

@media screen and (max-width: 600px) {
    .editDialogContent {
        width: 350px;
    }
}

@media screen and (max-width: 420px) {
    .editDialogContent {
        width: 300px;
    }
}

@media screen and (max-width: 350px) {
    .editDialogContent {
        width: 270px;
    }
}
