.statisticsContent {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    width: 100%;
    height: 100%;
}

.statisticBlock {
    padding: 16px;
    border-radius: 6px;
    border: 1px solid var(--CFD-theme-System-OutlineVariant);
    background-color: var(--CFD-theme-System-Surface);
    min-width: calc(50% - 46px);
    gap: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 92px;
}

.statisticBlockHeader {
    color: var(--CFD-theme-System-OnSurfaceVariant);
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    display: flex;
    gap: 12px;
    align-items: center;
}

.statisticBlockHeaderIcon {
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
    background-color: var(--CFD-theme-Surface-5);
    min-width: 18px;
    min-height: 18px;
    max-width: 18px;
    max-height: 18px;
}

.statisticBlockRow {
    display: flex;
    justify-content: space-between;
}

.statisticBlockRow .statisticBlockColumn:first-of-type {
    align-items: flex-start;
}

.statisticBlockRow .statisticBlockColumn:last-of-type {
    align-items: flex-end;
}

.statisticBlockColumn {
    display: flex;
    gap: 4px;
    flex-direction: column;
}

.svg {
    height: 18px;
    width: 18px;
}

.statisticBlockValue {
    color: var(--CFD-theme-System-OnSurface);
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
}

.statisticBlockSubValue {
    color: var(--CFD-theme-System-Outline);
    text-align: right;
    font-family: Arial;
    font-size: 12.006px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.008px;
    letter-spacing: 0.5px;
}

@media screen and (max-width: 1145px) {
    .statisticBlock {
        width: 100%;
    }
}

@media screen and (max-width: 904px) {
    .statisticsContent {
      width: 100%;
    }
  }