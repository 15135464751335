.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  gap: 24px;
  padding-left: 24px;
  padding-right: 24px;
  overflow: scroll;
}

.currencySection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  gap: 16px;
}

.selectWrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.currencySectionText {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-Outline);
}
.labelCoin {
  margin-left: 16px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; 
  letter-spacing: 0.4px;
}

.popularOptions {
  display: flex;
  flex-direction: row;
  gap: 12px;

}

.popularContainer {
  display: flex;
  border-radius: 8px;
  background-color: var(--CFD-theme-System-Surface);
  align-items: center;
  justify-content: center;
  gap: 6px;
  width: 80px;
  height: 32px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  border: none;
  outline: none;
}

.activePopular {
  background-color: var(--CFD-theme-System-SecondaryContainer);
}

.popularText {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.1px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}


.networkSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  gap: 16px;
}

.scanWalletSection {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.info {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  gap: 8px;
  padding: 14px;
  background-color: var(--CFD-theme-System-Inversesurface);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
}

.infoText {
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.25px;
  color: var(--CFD-theme-System-Inverseonsurface)
}

.qrCodeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 24px;
}

.qrCode {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  background-color: white;
  width: 200px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}

.qrCodeNumber {
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-Primary);
}

.footer {
  position: fixed;
  bottom: 0;
  box-sizing: border-box;
  width: 530px;
  display: flex;
  padding: 24px;
  background-color: var(--CFD-theme-Surface-1);
  border-top: 1px solid var(--CFD-theme-System-OutlineVariant);
}

.btnContainer {
  width: 100%;
}

.btnText {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  text-align: center;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.1px;
}

@media screen and (max-width: 599px) {
  .wrapper {
    height: 100vh;
    padding-bottom: 50px;
  }

  .qrCodeNumber {
    font-size: 14px;
  }

  .footer {
    width: 100%;
  }
}