.wrapper {
  background-color: var(--CFD-theme-System-Surface);
  width: 100%;
  height: auto; 
  box-sizing: border-box;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
}

.betweenAccountsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

@media screen and (max-width: 1239px) {
  .betweenAccountsWrapper {
    display: none;
  }
  .wrapper  {
    padding-top: 0;
  }
}

@media screen and (max-width: 599px) {
  .wrapper  {
    padding-left: 0;
    padding-right: 0;
  }
}

