.passwordSettingsWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    align-items: center;
    gap: 12px;
    padding-top: 16px;
    padding-bottom: 8px;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    align-items: center;
}

.passwordContainer {
    display: flex;
    flex-direction: column;
    height: 64px;
    width: 100%;
    gap: 2px;
    align-items: flex-start;
}

.passwordInputContainer {
    width: 100%;
    min-width: 360px;
}

.passwordTitle  {
    width: 190px;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; 
    letter-spacing: 0.4px;
    color: var(--CFD-theme-System-OnSurfaceVariant);
}

.btnWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.btnContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    max-width: 360px;
}

.btnSpan {
    text-align: center;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
    opacity: 0.38;
    color: var(--CFD-theme-System-Outline);
}

.passwordSettingsFooterBtns {
    margin-top: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.passwordBtn {
    width: 100%;
    max-width: 360px;
}

.btnForgotPass {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media screen and (max-width: 1320px) {
    .passwordContainer {
        flex-direction: column;
        margin-bottom: 10px;
    }
    .passwordTitle {
        max-width: 330px;
        width: 100%;
    }
    .btnWrapper {
        flex-direction: column;
        margin-bottom: 10px;
    }
    .passwordSettingsFooterBtns {
        flex-direction: column;
    }
}

@media screen and (max-width: 425px) {
    .passwordInputContainer {
        min-width: 280px;
    }
}