.accardionWrapper {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--CFD-theme-Surface-3);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 8px 16px;
}

.accardionTopCell {
  display: flex;
  padding: 16px 0 16px 0;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid var(--CFD-theme-System-OutlineVariant);
  gap: 8px;
}

.accardionBottomCell {
  display: flex;
  padding: 16px 0 16px 0;
  flex-direction: column;
  width: 100%;
  gap: 8px;
}

.accardionBtnCell {
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  padding-bottom: 16px;
  width: 100%;
}

.accardionTopCellTitle {
  color: var(--CFD-theme-System-Outline);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: 0.5px;
}

.accardionTopCellText {
  color: var(--CFD-theme-System-OnSurface);
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.accardionCellRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}



