.inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  margin-bottom: 4px;
}

.inputMaxButton {
  position: absolute;
  height: 30px;
  width: 45px;
  background-color: inherit;
  outline: none;
  border: none;
  cursor: pointer;
  color: var(--CFD-theme-System-Primary);
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  letter-spacing: 0.5px;
  padding-left: 4px;
  padding-right: 0;
}
.currencyTitle {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 10px;
  position: absolute;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}

.iconsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}