.mainWrapper {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.bodyWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    min-height: calc(100vh - 200px);
}

.header {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    padding: 24px;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    padding: 40px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    box-sizing: border-box;
    border-top: 1px solid var(--CFD-theme-System-OutlineVariant);
    background-color: var(--CFD-theme-Surface-1);
}

.contentWrapper {
    display: flex;
    height: 48px;
    width: 100%;
    gap: 16px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.contentTitle {
    font-family: Arial;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    color: var(--CFD-theme-System-OnSurface);
}

.closeButton {
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
}

.inputListWrapper {
    width: 100%;
    display: flex;
    padding: 24px 24px 32px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    box-sizing: border-box;
}

.inputGroup {
    display: flex;
    align-self: stretch;
    gap: 16px;
}

.inputGroup>div {
    width: 50%;
}

.maxPositionSpan {
    color: var(--CFD-theme-System-Outline);
}

.inputListWrapperChild {
    display: flex;
    align-self: stretch;
    gap: 16px;
}

.inputListWrapperChild>div {
    width: 50%;
}

.results {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
}

.resultsHeader {
    color: var(--CFD-theme-System-OnSurfaceVariant);

    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
}

.resultsBody {
    display: flex;
    padding: 4px 0px;
    flex-direction: column;
    align-items: flex-end;
    align-self: stretch;
    border-radius: 8px;
    background: var(--State-layers-on-surface-variant-opacity_08, rgba(189, 187, 208, 0.08));
    color: var(--CFD-theme-System-OnSurfaceVariant);
}

.resultsItem {
    display: flex;
    padding: 8px 12px 8px 16px;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    justify-content: space-between;
    border-bottom: 1px solid var(--State-layers-outline-opacity_16, rgba(144, 144, 154, 0.16));
    white-space: nowrap;
}

.resultsItem:last-child {
    border-bottom: none;
}

.resultsItemContainer {
    display: flex;
    align-items: center;
    gap: 5px;
    overflow-wrap: break-word;
    word-break: break-all;
    white-space: normal;
}

.symbolIcon {
    color: var(--CFD-theme-System-Outline);
}

.leverageContainer {
    margin-right: 20px;
}

@media screen and (max-width: 400px) {
    .mainWrapper {
        max-height: 100dvh;
    }
}