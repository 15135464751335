.rowWrapperTitle {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  letter-spacing: 0.5px;
  text-align: end;
  color: var(--CFD-theme-System-OnSurface);
}

.textGreen {
  color: var(--CFD-theme-Custom-Green);
}

.statusContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 4px;
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  width: fit-content;
  padding: 2px 4px;
  max-width: 90px;
  border-radius: 3px;
}

.dateText {
  color: var(--CFD-theme-System-Outline);
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
}

.statusWrapper {
  display: flex;
  flex-direction: column;
}

.typeText {
  color: var(--CFD-theme-System-OnSurface);
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.infoIconWrapper {
  background-color: rgba(183, 196, 255, 0.12);
  width: 24px;
  height: 24px;
  border: none;
  padding: 0px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 370px) {
  .rowWrapperTitle {
    font-size: 14px;
  }
}