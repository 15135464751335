.tooltip {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: transparent;
  width: auto;
  height: 334px;
}

.tooltipBox {
  display: inline-block;
  width: fit-content;
  background-color: var(--CFD-theme-System-Inverse-primary);
  padding: 4px 10px;
  border-radius: 2px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.tooltipHeader {
  color: var(--CFD-theme-System-OnSurface);
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.tooltipAmount {
  color: var(--CFD-theme-System-OnSurface);
  white-space: nowrap;
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.timeBlock {
  display: flex;
  flex-direction: row;
  width: fit-content;
  background-color: var(--CFD-theme-System-Inversesurface);
  color: var(--CFD-theme-System-Inverseonsurface);
  padding: 2px 6px;
  align-items: center;
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
