.docsProgreesListContainer {
  padding: 4px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  background-color: var(--CFD-theme-State-layers-secondary-container-opacity_16);
}

.docsProgreesList {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 12px 0 12px;
}

.docsProgreesListContent {
  padding-bottom: 12px;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid var(--CFD-theme-System-OutlineVariant);
  justify-content: space-between;
}

.docsProgreesListText {
  color: var(--CFD-theme-System-OnSurface);
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.docsProgreesListContentNoBorder {
  padding-bottom: 12px;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
}
