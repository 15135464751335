.fileContainer {
  box-sizing: border-box;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 500px;
  background-color: rgba(0, 0, 0, 0.4); ;
  border-radius: 6px;
  z-index: 15;
}



.file {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  justify-content: flex-start;
  width: 100%;
  width: 264px;
  border-radius: 12px;
  background-color: var(--CFD-theme-Surface-2);

}

.imgContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}

.imageHeader  {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  padding-left: 16px;
}

.imageName {
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.1px;
  color: var(--CFD-theme-System-OnPrimaryContainer);
}


.inputModal {
  width: 100%;
  box-sizing: border-box;
  outline: none;
  border-top: 1px solid var(--CFD-theme-System-OnSurfaceVariant);
  color: var(--CFD-theme-System-OnSurface);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  letter-spacing: 0.5px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
}

.img {
  width: 232px;
  height: 163px;
}

.btnImg {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  border-radius: 50%;
  width: 40px;
  height: 25px;
}

.btnImg:hover {
  background-color: var(--CFD-theme-Surface-2);;
}