.sectionWrapper {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  align-items: center;
}

.mainStep2 {
  width: 100%;
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid var(--CFD-theme-System-OutlineVariant)
}

.activatedText1 {
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;
  color: var(--CFD-theme-System-OnSurface);
  margin-bottom: 8px;
}

.activatedText2 {
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}

.secondStep2 {
  margin: 16px 0 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.riskLevel, .perDay {
  display: flex;
  justify-content: space-between;
  color: var(--CFD-theme-System-OnSurfaceVariant);
  font-size: 11px;
  letter-spacing: 0.5px;
}

@media screen and (max-width: 904px) {
  .sectionWrapper {
    overflow: scroll;
    max-height: calc(100dvh - 232px);
  }

  .activatedText1 {
    font-size: 22px;
    line-height: 28px;
  }

  .activatedText2 {
    font-size: 16px;
    line-height: 24px;
  }

  .riskLevel, .perDay {
    font-size: 16px;
  }
}

@media screen and (max-width: 400px) {
  .sectionWrapper {
    max-height: calc(100dvh - 212px);
  }
}
