.liquidationDialogHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 24px 0px 12px 0px;
  color: var(--CFD-theme-System-OnSurface);
  font-family: Arial;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  width: 356px;
}

.liquidationDialogFooter {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 11px;
  color: #fff;
  width: 340px;
}

.buttonsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  height: 70px;
}

.imgSize {
  width: 32px;
  height: 32px;
}

.headerTitle {
  color: var(--CFD-theme-System-OnSurface);
  text-align: center;
  font-family: Arial;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.additionalText {
  color: var(--CFD-theme-System-OnSurfaceVariant);
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  padding: 0px 15px;
}
