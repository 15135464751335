.sectionWrapper {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 24px;
  overflow-y: auto;
  overflow: scroll;
  max-height: calc(100dvh - 232px);
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 24px;
  margin-bottom: 16px;
}

.titleText {
  font-family: Arial;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-left: 10px;
  color: var(--CFD-theme-System-OnSurface);
}

.selectOptions {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.selectOptionsText {
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--CFD-theme-System-Outline);
}

.selectOptionsAssetsWrapper {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  width: 100%;
  height: 75px;
  margin-bottom: 20px;
  padding-left: 11px;
}

.selectOptionsAssetsWrapper>* {
  flex: 0 0 auto;
}

.selectWalletWrapper {
  display: flex;
  flex-direction: column;
}

.selectWalletContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}

.selectWallet {
  display: flex;
  flex-direction: column;
}

.selectWalletText {
  margin-top: 0;
  margin-bottom: 4px;
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--CFD-theme-System-Outline);
}

.wallet {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  padding-left: 8px;
  border-radius: 8px;
  background-color: var(--CFD-theme-System-SecondaryContainer);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
}

.balance {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.balanceText {
  margin-top: 0;
  margin-bottom: 4px;
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--CFD-theme-System-Outline);
}

.balanceAmount {
  margin-top: 4px;
  margin-bottom: 4px;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}

.selectWalletInfoWrapper {
  width: 450px;
  height: 100px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: var(--CFD-theme-System-Inversesurface);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
}

.selectWalletInfoContent {
  display: flex;
  flex-direction: row;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 14px;
  gap: 10px;

}

.selectWalletInfoContentText {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: var(--CFD-theme-System-Inverseonsurface);
}

.selectWalletInfoBtnWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.selectWalletInfoBtn {
  height: 40px;
  display: flex;
}

.btnText {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.subscriptionAmountWrapper {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.subscriptionAmountTitle {
  width: fit-content;
  margin-top: 0;
  margin-bottom: 5px;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.4px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}

.subscriptionAmountMax {
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  margin-bottom: 4px;
  margin-top: 0;
  color: var(--CFD-theme-System-Outline);
}

.subscriptionAmountMin {
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  margin-bottom: 4px;
  color: var(--CFD-theme-System-Outline);
}

.minMaxAmount {
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}

.summaryContainer {
  display: flex;
  flex-direction: column;
}

.summaryContainerText {
  margin-top: 20px;
  margin-bottom: 8px;
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--CFD-theme-System-Outline);
}

.summaryAmountContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.summaryAmountTitle {
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}

.summaryAmount {
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: var(--CFD-theme-System-Tertiary);
}

.emptySummaryContainer {
  display: none;
  height: 119px;
}

.subscriptionCurrencyConverterInfo {
  background-color: var(--CFD-theme-State-layers-on-surface-variant-opacity_08);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 12px;
  margin: 12px 0;
}

.subscriptionCurrencyConverterInfoRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subscriptionCurrencyConverterInfoRow span:first-of-type {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: left;
  color: var(--CFD-theme-System-OnSurfaceVariant)
}

.subscriptionCurrencyConverterInfoRow span:last-of-type {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4000000059604645px;
  color: var(--CFD-theme-System-OnSurface);
}

@media screen and (max-width: 904px) {
  .sectionWrapper {
    overflow: scroll;
    max-height: calc(100dvh - 232px);
  }
  .emptySummaryContainer {
    display: block;
  }
}

@media screen and (max-width: 400px) {
  .sectionWrapper {
    max-height: calc(100dvh - 214px);
  }

  .emptySummaryContainer {
    height: 99px;
  }

  .titleWrapper {
    margin-top: 10px;
    margin-bottom: 0px;
  }

  .summaryContainerText {
    margin-top: 0px;
  }

  .selectWalletContainer {
    margin-bottom: 0px;
    gap: 10px;
    flex-direction: column;
  }

  .subscriptionAmountWrapper {
    margin-top: 5px;
  }

  .selectOptions {
    margin-top: 0px;
  }
}

