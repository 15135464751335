.text {
  width: 100%;
  margin-left: 6px;
}

.customSelect {
  position: relative;
  width: 100%;
  font-family: Arial, sans-serif;
}

.selectValue {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 6px 8px 6px 16px;
  border: 1px solid var(--CFD-theme-System-Outline);
  border-radius: 8px;
  background-color: var(--CFD-theme-Surface-2);
  color: var(--CFD-theme-System-OnSurfaceVariant);
  cursor: pointer;
}

.selectValueText {
  font-family: 'Arial';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}

.selectValueText::first-letter {
  text-transform: uppercase;
}

.selectValue.open {
  border-color: var(--CFD-theme-System-Primary);
}

.optionsList {
  position: absolute;
  width: 200px;
  top: 100%;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  margin-top: 4px;
  list-style: none;
  border-radius: 8px;
  background-color: var(--CFD-theme-Surface-3);
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
  z-index: 5;
}

.option {
  padding: 8px 16px;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
  background-color: var(--CFD-theme-Surface-3);
  color: var(--CFD-theme-System-OnSurface);
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.icon {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.option:first-child {
  margin-top: 8px;
}

.option:last-child {
  margin-bottom: 8px;
}

.option:hover {
  background-color: var(--CFD-theme-State-layers-on-surface-opacity_08);
  color: var(--CFD-theme-System-OnSurface);
}

.option.selected {
  background-color: var(--CFD-theme-Surface-5);
  color: var(--CFD-theme-System-OnSurface);
}

.disabled .select-value {
  background-color: #f0f0f0;
  cursor: not-allowed;
}

.disabled .select-value span.icon {
  display: none;
}