.runningAdsWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  min-height: 48px;
  box-sizing: border-box;
  background-color: #192245;
}

.runningAds {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  position: relative;
  animation: ticker linear infinite;
  animation-duration: 20s;
}

.runningAdsText {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 10px;
  justify-content: space-around;
}

.svgHeight {
  height: 32px;
}

.binanceSvg {
  width: 160px;
}

.bingXSvg {
  width: 110px;
}

.byBitSvg {
  width: 83px;
}

.okxSvg {
  width: 100px;
}

.mecxSvg {
  width: 175px
}

@keyframes ticker {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media (max-width: 800px) {
  .runningAdsText {
    transform: translateZ(0);
  }
}

.runningAds > .runningAdsText {
  min-width: 100%;
  flex-shrink: 0;
}

.runningAds::after {
  content: '';
  flex-shrink: 0;
}