.dialogFooter {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    margin-bottom: 32px;
    margin-top: 32px;
    box-sizing: border-box;
    width: 100%;
    position: relative;
}

.dialogFooterSupportText {
    margin-left: 24px;
    margin-right: 16px;
    margin-bottom: -8px;
    color: var(--CFD-theme-System-Outline);
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.5px;
}

.dialogFooterButton {
    max-width: 450px;
    width: 100%;
}

@media screen and (max-width: 904px) {
    .dialogFooter {
        padding: 0 16px 10px 16px;
        margin-bottom: 0;
        margin-top: 0;
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: var(--CFD-theme-Surface-1);
    }
    .dialogFooter::after {
        content: "";
        position: absolute;
        top: -24px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: var(--CFD-theme-System-OutlineVariant);
        transform: scaleY(0.3);
        transform-origin: top;
    }
    .dialogFooterButton {
        max-width: unset;
    }
}

