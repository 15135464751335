.optionsCellWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--CFD-theme-Surface-5);
  background-color: var(--CFD-theme-Surface-3);
}

.optionsCellHeader {
  background-color: var(--CFD-theme-Surface-4);
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 12px 16px;
  box-sizing: border-box;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  cursor: pointer
}

.optionsCellHeader:hover {
  background-color: var(--CFD-theme-State-layers-on-surface-variant-opacity_08);
}

.optionsCellHeaderTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px
}

.optionsCellHeaderText {
  color: var(--CFD-theme-System-OnSurfaceVariant);
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.optionsCellBody {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  gap: 24px;
}

.amountSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.amountSectionText {
  color: var(--CFD-theme-System-OnSurface);
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.amountSectionSpan {
  margin-left: 4px;
}

.btnsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}

.depositBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 100px;
  padding: 8px 12px 8px 8px;
  gap: 6px;
  background-color: var(--CFD-theme-Surface-5);
}

.withdrawalBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 100px;
  padding: 8px;
  background-color: var(--CFD-theme-Surface-5);
}

.imgSize {
  width: 20px;
  height: 20px;
}

.linkBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  cursor: pointer;
  width: 26px;
  height: 26px;
  padding: 0;
  background-color: transparent;
}

.linkBtn:hover {
  border-radius: 100px;
  background-color: var(--CFD-theme-State-layers-on-surface-opacity_08);
}

.disableWithdrawalBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 100px;
  padding: 8px;
  opacity: 0.38;
  background-color: var(--CFD-theme-State-layers-inverse-on-surface-opacity_12);
}

.depositBtn:hover {
  background-color: var(--CFD-theme-State-layers-primary-opacity_08);
}

.withdrawalBtn:hover {
  background-color: var(--CFD-theme-State-layers-primary-opacity_08);
}

.svg {
  color: var(--CFD-theme-System-Primary);
  width: 20px;
  height: 20px;
}

.svgTitle {
  color: var(--CFD-theme-System-Primary);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.infoSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  gap: 16px;
  flex-wrap: wrap;
}

.infoSectionCell {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  gap: 4px;
}

.infoSectionCellTitle {
  color: var(--CFD-theme-System-Outline);
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
}

.infoSectionCellText {
  color: var(--CFD-theme-System-OnSurfaceVariant);
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
}