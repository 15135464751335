.footerWrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  padding: 24px;
}

.footerAgreement {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
}

.footerAgreementText {
  color: var(--CFD-theme-System-OnSurfaceVariant);
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.footerAgreementLink {
  margin-left: 5px;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-decoration: none;
  color: var(--CFD-theme-System-Tertiary);
}

.footerBtnContainer {
  width: 100%;
}

.footerBtnContainerStep2 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 16px;
}

@media screen and (max-width: 904px) {
  .footerWrapper {
    background-color: var(--CFD-theme-Surface-2);
    padding: 24px;
    position: fixed;
    bottom: 0;
  }
}