.docsProgreesListLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  padding: 2px 4px;
  color: var(--CFD-theme-System-Outline);
  text-align: center;
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
  border-radius: 3px;
}