.statusContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 4px;
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  width: fit-content;
  padding: 2px 4px;
  max-width: 90px;
  border-radius: 3px;
}