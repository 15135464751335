.section {
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
}

.transferInfoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-bottom: 24px;
  margin-top: 18px;
}

.transferInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 130px;
  background-color: var(--CFD-theme-State-layers-surface-variant-opacity_16);
  border-radius: 6px;
}

.transferInfoTitle {
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-Outline);
}

.amountContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.fromAmount {
  font-family: Arial;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: var(--CFD-theme-System-OnSurface);
}

.toAmount {
  text-align: center;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-Outline);
}

.rateWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 8px;
}

.rateTitle {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
  letter-spacing: 0.25px;
  color: var(--CFD-theme-System-Outline);
}

.rateText {
  margin-top: 0;
  margin-bottom: 0;
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-Outline);
}

.rateAmountContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.fromRate {
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}

.toRate {
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: var(--CFD-theme-System-Primary);
}

.successWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.successSummary {
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--CFD-theme-System-OutlineVariant);
  width: 100%;
  gap: 10px;
}

.successSummary > :first-child {
  margin-top: 20px;
}

.successSummary > :last-child {
  margin-top: 8px;
}

.summuryContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.summaryLabel {
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-OnSurfaceVariant);
}

.summaryValue {
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: 0.5px;
  color: var(--CFD-theme-System-OnSurface);
}