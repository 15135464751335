.btnContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 16px;
  gap: 16px;
  width: 100%
}

.btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.footerWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 16px;
  gap: 16px;
  width: 100%;
  background-color: var(--CFD-theme-Surface-4);
  border-top: 1px solid var(--CFD-theme-System-OutlineVariant);
}

.footerTitleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 4px;
}

.footerTitle {
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  white-space: nowrap;
}

.footerBtnContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.seccesContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.signatureContainer {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
}

@media screen and (max-width: 599px) {
  .footerWrapper {
    flex-direction: column;
  }
}
